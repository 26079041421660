export default `SPERMCELL MOBILE APPLICATION
END USER LICENSE AGREEMENT
This Mobile Application End User License Agreement (“Agreement”) is a binding agreement between you (“End User” or “you”) and Hillal Biotech, LTD (“Company”, “we”, or “us”). This Agreement governs your use of the Spermcell mobile application (the “Application”, “Spermcell”) in connection with the Spermcell Kit (the “Kit”). The Application is licensed, not sold, to you.

BY USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER/OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.

1. THE APPLICATION
(a) When used with the Kit and in accordance with the Kit’s instructions (the “Test Kit Instructions”), the Application provides a sperm quality test you can do in the privacy of your own home (the “Service”), the results of which will use a semen Sample (the “Sample”) to report on your Motile Sperm Concentration (“MSC”) and provide a live video of your sperm cells (collectively, the “Results”).

(b) YOU ACKNOWLEDGE AND AGREE THAT YOU WILL FOLLOW THE TEST KIT INSTRUCTIONS, INCLUDING ITS EXPRESS WARNINGS REGARDING THE BIOLOGICAL HAZARDS ASSOCIATED WITH COLLECTING, HANDLING AND DISPOSING OF THE SAMPLE, WHICH INCLUDE, BUT ARE NOT LIMITED TO, THE POTENTIAL TRANSMISSION OF SEXUALLY TRANSMITTED DISEASES. YOU AGREE TO INDEMNIFY AND DEFEND COMPANY FROM ANY AND ALL THIRD PARTY CLAIMS ARISING OUT OR CONNECTED WITH YOUR FAILURE TO FOLLOW THE TEST KIT INSTRUCTIONS, AND HOLD COMPANY HARMLESS FROM ANY AND ALL DAMAGES, COSTS, EXPENSES (INCLUDING ATTORNEYS’ FEES), and LIABILITIES THAT MAY RESULT THEREFROM.

(c) The Application is free, but cannot be used without the Kit, which must be purchased from Company or other authorized dealers. Different Kits are required depending on the type of what you are analyzing. If you want to analyze human semen you should have Spermcell Human Kit, if you want to analyze bovine semen then you should have Spermcell Bovine Kit.

2. LICENSE GRANT
Subject to the terms of this Agreement, Company grants you a limited, non-exclusive and nontransferable license to download, install and use the Application for your personal, non-commercial use on a single mobile device owned or otherwise controlled by you (“Mobile Device”).

3. LICENSE RESTRICTIONS
You shall not:

(a) Copy the Application;

(b) Modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Application;

(c) Reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Application or any part thereof; and

(d) Remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Application, including any copy thereof.

4. RESERVATION OF RIGHTS
You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions and restrictions, under this Agreement. Company reserves and shall retain its entire right, title and interest in and to the Application, including all copyrights, trademarks and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.

5. PRIVACY
(a) Privacy & Medical Confidentiality. Your privacy and medical confidentiality is very important to us. You should read our Privacy Policy located on our website in full prior to using the Application. The Application labeling advises you to password protect your device prior to using the Application. Should you decide not to do this and any information is shared, with or without your consent, as a result of an unauthorized party accessing your device, including information related to your Results, this is your sole responsibility, and we shall not be liable for any injuries as a result thereof.

(b) Collection & Use of Personal Information. The Application will request collect personal information from you. But you don't have to give them. You can do your analyzes it without giving any information to the application. The analyze results will store on the server machine's database system but not your personel information. Disclosing, sharing, and/or disseminating your Results is thus at your sole discretion and risk.

(c) Security. We advise, but it is your responsibility to password protect your Mobile Device and retain this password protection while confidential information related to your use of the Application (including the Results) is stored on the Application which is installed on your Mobile Device so that others cannot view your sensitive data. You are responsible to maintain the security of your Mobile Device password and to maintain that password security as long as the Application is installed on your Mobile Device. You are responsible for maintaining the security of your Results and for deleting the Results if you decide to discontinue using password protection for your Mobile Device.

6. RESULTS
(a) The Application provides Results that are > 90% accurate. It is therefore possible for you to receive an inaccurate Result;

(b) The Results are not diagnostic;

(c) The Results do not replace the advice of a medical professional and you are hereby advised to seek professional advice and care in addition to using this Application and the Kit;

(d) Failure to comply with the Spermcell Kit’s Instructions and/or the Application’s instructions can produce an erroneous Result; and/or

(e) Your Results may no longer be accessible or may be deleted if/when you delete the Application, re-install the Application or a version thereof or install or update your Mobile Device’s operating system, or if viruses or other invasive or unexpected software disrupt, attack, alter or interfere with your Mobile Device.

7. UPDATES
Company may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, “Updates”). Updates may also modify or delete in their entirety certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality.

8. THIRD PARTY MATERIALS
The Application may display, include or make available third-party content (including data, information, applications and other products services and/or materials) or provide links to third-party websites or services, including through third-party advertising (“Third Party Materials”). You acknowledge and agree that Company is not responsible for Third Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you and you access and use them at entirely at your own risk and subject to such third parties’ terms and conditions.

9. TERM & TERMINATION
The term of this Agreement commences when you install the Application and will continue in effect until terminated by you or Company.

(a) You may terminate this Agreement by deleting the Application and all copies thereof from your Mobile Device.

(b) Company may terminate this Agreement at any time without notice. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.

(c) Upon termination:

(i) all rights granted to you under this Agreement will also terminate; and

(ii) you must cease all use of the Application and delete all copies of the Application from your Mobile Device.

(d) Termination will not limit any of Company’s rights or remedies at law or in equity.

10. DISCLAIMER OF WARRANTIES
WE MAKE A CONCERTED EFFORT TO MAINTAIN THE APPLICATION, KEEP IT BUG FREE AND SAFE, BUT YOU USE IT AT YOUR OWN RISK. THE APPLICATION IS PROVIDED TO YOU “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED (INCLUDING ANY IMPLIED WARRANTIES FOR 100% ACCURATE RESULTS OR DIAGNOSIS OF ANY MEDICAL CONDITION), STATUTORY OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.

11. LIMITATION OF LIABILITY
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:

(a) PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES;

(b) DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED ONE HUNDRED US DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE (12) MONTHS.

THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.

12. INDEMNITY
You agree to defend, indemnify and hold harmless Company and its affiliates from and against all claims, losses, costs and expenses (including attorney’s fees) arising out of or related to (a) your use of, or activities in connection with, the Application, its Services, and/or the Kit; and/or (b) any violation of this Agreement by you. We reserve the right to assume all or any part of the defense of any such claims and negotiations for settlement and you agree to fully cooperate with us in doing so.

13. GOVERNING LAW
This Agreement is governed by and construed in accordance with the laws of the State of California without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of or related to this Agreement or the Application shall be instituted exclusively in the United States District Court for the Southern District of California or a state court located in Los Angeles County, California. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.

14. GENERAL PROVISIONS
This Agreement constitutes the entire agreement between you and Company governing your use of the Application. The failure of Company to exercise or enforce any term of this Agreement will not constitute a waiver of such term. If the application of any provision of this Agreement to any particular facts or circumstances shall for any reason be held to be invalid, illegal or unenforceable by a court, arbitrator or other tribunal of competent jurisdiction, then (a) the validity, legality and enforceability of such provision as applied to any other particular facts or circumstances, and the other provisions of this Agreement, shall not in any way be affected or impaired thereby and (b) such provision shall be enforced to the maximum extent possible so as to effect the intent of the parties.

15. AGREEMENT TO THE TERMS
By agreeing to the terms you attest that you are 18 years or older or of the age of majority in the state/country in which you reside and have read and agree to this END USER LICENSE AGREEMENT.`
