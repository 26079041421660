import React from 'react'

import useQRCodes from 'hooks/qrCode/useQRCodes'
import useQRCodeColumns from 'hooks/qrCode/useQRCodeColumns'
import useHandleTableChange from 'hooks/useHandleTableChange'

import AppLayout from 'common/layouts/app.layout'
import TableView from 'common/views/table.view'
import QRCodeCreateModalButton from 'common/views/qrCode/qrCodeCreateModalButton'

const QRCodePage = () => {
  const { page, size, order, handleTableChange } = useHandleTableChange()

  const { data, isLoading, refetch } = useQRCodes({
    page: Number(page) - 1,
    size,
    sort: 'createdDate,desc',
    order,
  })
  const columns = useQRCodeColumns()

  return (
    <AppLayout>
      <TableView
        title="QR Codes"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        actionButton={<QRCodeCreateModalButton refetch={refetch} />}
        handleTableChange={handleTableChange}
        totalCount={data?.totalElements}
      />
    </AppLayout>
  )
}

export default QRCodePage
