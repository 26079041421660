import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'common/components/buttons/button'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import useSendResult from 'hooks/results/useSendResult'
import { toast } from 'react-toastify'

type Props = {
  id: string
}

const SendResultCell: React.FC<Props> = ({ id }) => {
  const { mutateAsync, isLoading } = useSendResult(id)

  const onClick = () => {
    mutateAsync().then(() => {
      toast('Result successfully sent.', {
        type: 'success',
      })
    })
  }

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        status={isLoading ? 'fetching' : undefined}
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faEnvelope} />
          <p>Send Result</p>
        </div>
      </Button>
    </div>
  )
}

export default SendResultCell
