export default `Effective Date: September 1, 2017

Hillal Biotech (hereafter “HB,” “we,” “us,” or “our”) cares about your privacy. This privacy policy (“Privacy Policy”) describes the types of information we collect through our Spermcell Mobile Application (the “App”) and how we use it. This App only functions in conjunction with the Spermcell Kit. Your continued use of our products and services, as well as your use of this App, constitutes your consent to the terms of this Privacy Policy and any updates. This Privacy Policy is incorporated into, and is subject to, the Spermcell Terms of Use. If you do not agree to the Terms and Conditions or this Privacy Policy, please do not use the App.

I. Information We Collect and How We Use It
In general, you can use our App without submitting any personal information. In order for the App to analyze your sample collected using the Spermcell Kit, the App will access your device camera with your permission and capture a video of the sample. The video captured will be used for the sole purpose of analyzing the Spermcell Kit sample and providing you with test results. Captured video will be stored the device's general video database so if you won't have internet connection or want to analyze another time you can use it from gallery. The test results provided by the App will be stored locally on your mobile device in the App’s archives, not in your mobile device’s general picture or video database. The internet connection will be used for sending captured video to the server for analyze it. If you want to see your personel information (name, surname, birth date and diet) in your result report, you should put this data on the input screen otherwise you can pass this screen without give any information. We do not collect any additional non-personal information from you through automated means. We do not use “cookies” and similar technologies at this time to gather and store technical and usage information about App users.

II. Information We Share
Except as described in this Privacy Policy, we will not disclose your personal information outside of HB without your consent. We may, however, share the personal information we collect from you in limited circumstances, including: We may disclose to our suppliers, contractors who process your personal information to provide services to us or you, for example developing or creating our App, customer service, and providing brand assistance. In some instances, these contractors may have access to personal information, but they are contractually obligated to maintain the confidentiality of the information and to use it only for purposes of carrying out the business of HB. We may disclose to any law enforcement agency, court, regulator, government authority or other third party where we believe this is necessary to comply with a legal or regulatory obligation, in order to enforce our Terms and Conditions, or otherwise to protect our rights/property or the rights/property of any third party. This includes sharing certain information with other companies and organizations for the purposes of fraud protection and credit risk reduction, if needed. We may disclose to any third party that purchases, or to which we transfer, all or substantially all of our assets and business. Should such a sale or transfer occur, we will use reasonable efforts to try to ensure that the entity to which we transfer your personal information uses it in a manner that is consistent with this Privacy Policy.

III. How We Protect Your Information
HB cares about the security and confidentiality of your information. Your personel information and test results will be securely stored in our database servers. We require that you have a password protected device. Should you disable the password protection, any information shared, with or without your consent, as a result of an unauthorized party accessing your device, including information related to your test results, is your sole responsibility, and we shall not be liable for any injuries as a result thereof. Additionally, we maintain commercially reasonable administrative, technical and physical security measures to protect the security of the information you submit to us. However, no mobile application is completely secure or “hacker proof.” You are also responsible for taking reasonable steps to protect your personal information against unauthorized disclosure or misuse.

IV. Privacy of Children
This App is not intended for children. By using our App, you represent that you are at least 18 years old. It is our policy not to knowingly collect personal information from any person under the age of 13, and we request that children under the age of 13 not submit any personal information to us through the App. If we learn that we have inadvertently gathered personal information from children under 13, we will promptly remove such information from our records.

V. Changes to the Privacy Policy
This Privacy Policy may be amended from time to time. Any such changes will be posted on this page. Accordingly, we recommend that you consult this page frequently so that you are aware of our latest policy and/or any changes to this Privacy Policy. Your continued use of our App after we have posted notice of such modifications, alterations or updates shall constitute your acceptance of any such new or revised Privacy Policy.

VI. How to Contact Us
If you have any comments or questions regarding this Privacy Policy, please contact us at info@hillal.eu`
