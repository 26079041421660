import React, { forwardRef } from 'react'
import clsx from 'clsx'

import Alert from '../alert'

type InputProps = {
  placeholder?: string
  error?: string
  type?: string
  label?: string | React.ReactNode
  className?: string
  containerClassName?: string
  labelClassName?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  requiredField?: boolean
  disabled?: boolean
  step?: string
  lang?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    placeholder,
    type,
    error,
    label,
    startAdornment,
    endAdornment,
    className,
    containerClassName,
    labelClassName,
    requiredField,
    disabled,
    lang,
    ...rest
  } = props

  return (
    <div
      className={clsx(
        '',
        containerClassName,
        disabled && 'pointer-events-none'
      )}
    >
      <div className="relative">
        <div className="text-lg font-normal text-gray-700 mb-2 flex shrink-0">
          <span className={clsx('', labelClassName)}>{label}</span>
          {requiredField && <span className="text-red-600">*</span>}
        </div>
        {(startAdornment || endAdornment) && (
          <span
            className={clsx(
              'absolute inset-y-7 flex items-center',
              startAdornment && 'left-0 pl-3',
              endAdornment && type === 'number'
                ? 'right-0 pr-9'
                : 'right-0 pr-3'
            )}
          >
            {startAdornment || endAdornment}
          </span>
        )}
        <input
          type={type}
          placeholder={placeholder}
          className={clsx(
            'py-2 px-4 rounded-lg w-full border-solid border-2 border-gray-200 focus:border-primary-200 text-black',
            className,
            disabled && 'bg-gray-200 text-gray-600'
          )}
          lang={lang}
          {...rest}
          ref={ref}
        />
      </div>
      {error && <Alert type="error" message={error} />}
    </div>
  )
})

Input.defaultProps = {
  placeholder: '',
  error: '',
  type: 'text',
  label: '',
  className: '',
  containerClassName: '',
  labelClassName: '',
  startAdornment: null,
  endAdornment: null,
  requiredField: false,
  disabled: false,
  step: '1',
  lang: 'en',
}

export default Input
