import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useNewPassword from 'hooks/auth/useNewPassword'

import PublicLayout from 'common/layouts/public.layout'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Link from 'common/components/link'

const NewPasswordPage = () => {
  const methods = useForm()
  const { mutate, isSuccess, isLoading, error } = useNewPassword()

  const onSubmit = (data: any) => mutate(data)

  useEffect(() => {
    if (isSuccess) {
      toast('Password changes successfully!', { type: 'success' })
    }
  }, [isSuccess])

  useEffect(() => {
    if (error instanceof Error) {
      toast(error.message, { type: 'error' })
    }
  }, [error])

  return (
    <PublicLayout>
      <div className="flex flex-col p-16 mx-auto my-12 space-y-6 tablet:rounded-3xl shadow-lg bg-slate-50 w-full tablet:w-[544px]">
        <h1 className="text-2xl">Reset Your Password</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <Input
              label="New Password"
              type="password"
              placeholder="Enter new password"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={methods.formState.errors?.password?.message}
              {...methods.register('password', {
                required: 'Please enter a password.',
              })}
            />
            <Input
              label="New Password (Again)"
              type="password"
              placeholder="Enter new password again"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={methods.formState.errors?.passwordAgain?.message}
              {...methods.register('passwordAgain', {
                required: 'Please enter a password.',
              })}
            />
            <SubmitButton status={isLoading ? 'disabled' : ''}>
              Reset Password
            </SubmitButton>
            <Link href="/login" variant="outlined">
              Go Back to Login
            </Link>
          </form>
        </FormProvider>
      </div>
    </PublicLayout>
  )
}

export default NewPasswordPage
