import React, { useState } from 'react'

import Modal from './modal'
import Button from '../buttons/button'

type ButtonModalProps = {
  children: React.ReactNode
  variant?: 'contained' | 'outlined' | 'default' | 'dashed'
  title?: string
  content?: string | React.ReactNode
  status?: 'danger' | 'success' | 'warning' | 'primary'
  onAccept?: () => void
  onReject?: () => void
  acceptButtonCaption?: string
  rejectButtonCaption?: string
  containerClassName?: string
  buttonSize?: 'large' | 'medium' | 'small'
}

const ButtonModal: React.FC<ButtonModalProps> = ({
  children,
  variant,
  onAccept,
  onReject,
  title,
  content,
  status = 'primary',
  acceptButtonCaption,
  rejectButtonCaption,
  containerClassName,
  buttonSize,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  return (
    <Button
      onClick={() => setIsModalOpen(true)}
      variant={variant}
      className={containerClassName}
      size={buttonSize}
    >
      {children}
      <Modal
        isOpen={isModalOpen}
        title={title}
        content={content}
        status={status}
        onAccept={onAccept}
        onReject={onReject}
        acceptButtonCaption={acceptButtonCaption}
        rejectButtonCaption={rejectButtonCaption}
        closeModal={closeModal}
      />
    </Button>
  )
}

ButtonModal.defaultProps = {
  variant: 'default',
  status: 'primary',
  buttonSize: 'medium',
  title: '',
  content: '',
  acceptButtonCaption: '',
  rejectButtonCaption: '',
  containerClassName: '',
  onAccept: () => {},
  onReject: () => {},
}

export default ButtonModal
