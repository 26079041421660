/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { Row, Col, Form, Input, Select, InputNumber } from 'antd'

// todo responsedan donunce, data'dan appearance gibi degerler gosterilecek
const AnalysisTable: React.FC = () => {
  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Russian', value: 'ru' },
  ]

  const spermOptions = [
    { label: 'Human', value: 'HUMAN' },
    { label: 'Bull', value: 'BULL' },
  ]

  return (
    <div className="mt-4">
      <h1 className="text-lg font-bold">Analysis Information</h1>
      <Row className="mt-4">
        <Col className="flex-1">
          <Form.Item
            name={['language']}
            label="Language"
            rules={[{ message: 'Language must be chosen!', required: true }]}
          >
            <Select options={languageOptions} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col className="flex-1" xs={11}>
          <Form.Item
            name={['type']}
            label="Sperm Type"
            rules={[{ message: 'Sperm type must be chosen!', required: true }]}
          >
            <Select options={spermOptions} />
          </Form.Item>
        </Col>
        <Col className="flex-1" offset={2} xs={11}>
          <Form.Item
            name={['volume']}
            label="Volume"
            rules={[{ message: 'Volume must be entered!', required: true }]}
          >
            <InputNumber
              min={Number(0)}
              max={Number(100)}
              step={0.01}
              formatter={(value) => String(value).replace(/\./g, ',')}
              parser={(value) => parseFloat(String(value).replace(/,/g, '.'))}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default AnalysisTable
