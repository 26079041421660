import * as api from './api'

export const enqueueResult = async (id: string) =>
  api.post(`/casa-analyze-result/${id}/enqueue`)

export const runCasaResult = async (body: any) => {
  api.post(`/casa-analyze-result`, body)
}

export const deleteCasaResult = async (id: string) => {
  api.del(`/casa-analyze-result/${id}`)
}
