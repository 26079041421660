import React, { useMemo } from 'react'

import StatusCell from 'common/views/statusCell'
import { Product } from 'types'

const useProductColumns = () => {
  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Status',
        render: (data: Product) => <StatusCell status={data.active} />,
      },
    ],
    []
  )
  return columns
}

export default useProductColumns
