import React, { useMemo } from 'react'
import { toast } from 'react-toastify'

import DeleteCell from 'common/components/Table/deleteCell'
import RoleCell from 'common/views/users/roleCell'
import { User } from 'types'
import useDeleteUser from './useDeleteUser'

const useUserColumns = ({ refetch }: { refetch: () => void }) => {
  const { mutateAsync, isSuccess } = useDeleteUser()

  const handleDelete = (id: string) => {
    mutateAsync(id).then(() => {
      toast('User deleted succesfully.', { type: 'success' })
      refetch()
    })
  }

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: '',
        key: '',
        render: (data: User) => <p>{`${data?.name} ${data?.surname}`}</p>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Role',
        dataIndex: '',
        key: '',
        // eslint-disable-next-line jsx-a11y/aria-role
        render: (data: User) => <RoleCell role={data?.role} />,
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (id: string) => (
          <DeleteCell
            modalTitle="Delete User"
            modalContent="Are you sure you want to delete user? This action cannot be undone."
            handleDelete={() => handleDelete(id)}
            isSuccess={isSuccess}
          />
        ),
      },
    ],
    []
  )

  return columns
}

export default useUserColumns
