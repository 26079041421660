import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import useProducts from 'hooks/products/useProducts'

import Select from 'common/components/select'
import { CreateQRCodeBody } from 'types'

const QRCodeCreateModalForm = () => {
  const methods = useFormContext<CreateQRCodeBody>()
  const {
    control,
    formState: { errors },
  } = methods

  // TODO
  const { data } = useProducts({ page: 0, size: '100' })

  const availableProductOptions = useMemo(
    () =>
      data?.content
        .filter((product) => product.active)
        .map((item) => ({ label: item.name, value: item.id })),
    [data]
  )

  return (
    <div className="flex flex-col gap-4">
      <Controller
        name="productId"
        control={control}
        defaultValue=""
        rules={{ required: 'Please select a SpermCell type.' }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={availableProductOptions || []}
            id="QRCodeOptions"
            label="Product"
            onChange={(val) => onChange(val)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={errors.productId?.message}
            value={value}
            placeholder="Sperm Cell Type"
            requiredField
          />
        )}
      />
    </div>
  )
}

export default QRCodeCreateModalForm
