import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import PublicRoutes from './public'
import AppRoutes from './app'
import ErrorPage from './error.page'

const NavigationRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<PublicRoutes />} />
      <Route path="/app/*" element={<AppRoutes />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
)

export default NavigationRoutes
