import React from 'react'
import clsx from 'clsx'

import { Form, FormInstance, InputNumber } from 'antd'
import { getFormatter } from 'utils/getFormatter'
import { getParserFloat } from 'utils/getParserFloat'
import getLocaleNumberFormat from 'utils/getLocaleNumberFormat'
import Caption from '../caption'

const totalSpermReferenceValue = 15000000
const volumeReferenceValue = 1.5

type ConcentrationTableProps = { form: FormInstance }

const ConcentrationTable: React.FC<ConcentrationTableProps> = ({
  form,
}: ConcentrationTableProps) => {
  const totalSperm = Form.useWatch('totalSperm', form)
  const volume = Form.useWatch('volume', form)

  return (
    <>
      <Caption>Concentration</Caption>
      <table className="w-full table-auto">
        <tbody>
          <tr>
            <td className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px]">
              <Form.Item
                name={['totalSperm']}
                style={{ flex: '1', marginTop: '8px', marginBottom: '0px' }}
                rules={[
                  {
                    message: 'Please enter total sperm value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  formatter={(target) =>
                    getFormatter(target, /\B(?=(\d{3})+(?!\d))/g, '.')
                  }
                  parser={(target) => getParserFloat(target, /\./g, '')}
                />
              </Form.Item>
            </td>
            <td className=" bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] ">
              {getLocaleNumberFormat(totalSperm * volume)
                .replace(/,/g, '|')
                .replace(/\./g, ',')
                .replace(/\|/g, '.')}{' '}
              Sperm/Ejac.
            </td>
            <td
              className={clsx(
                ' bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] ',
                volume > volumeReferenceValue ? 'text-black ' : 'text-red-500 '
              )}
            >
              Volume (mL)
            </td>
            <td className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] ">
              Dilution
            </td>
          </tr>
          <tr className="text-xs italic text-gray-400 ">
            <td>
              Ref &gt; {getLocaleNumberFormat(totalSpermReferenceValue)}{' '}
              Sperm/mL
            </td>
            <td> </td>
            <td>Ref &gt; {getLocaleNumberFormat(volumeReferenceValue)} mL</td>
            <td> </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default ConcentrationTable
