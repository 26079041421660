import { faRepeat } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'common/components/buttons/button'
import useEnqueueResult from 'hooks/results/useEnqueueResult'
import React from 'react'
import { toast } from 'react-toastify'

type Props = {
  id: string
}

const EnqueueResultCell: React.FC<Props> = ({ id }) => {
  const { mutateAsync, isLoading } = useEnqueueResult(id)

  const onClick = () => {
    mutateAsync().then(() => {
      toast('Result successfully enqueued.', {
        type: 'success',
      })
    })
  }

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation()
          onClick()
        }}
        status={isLoading ? 'fetching' : undefined}
      >
        <div className="flex items-center gap-2 text-sm">
          <FontAwesomeIcon icon={faRepeat} />
          <p>Enqueue</p>
        </div>
      </Button>
    </div>
  )
}

export default EnqueueResultCell
