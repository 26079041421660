import React from 'react'

type VideoPlayerProps = {
  data: { src: string }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VideoPlayer: React.FC<VideoPlayerProps> = ({ data }) => (
  <video controls playsInline src={`${data.src}#t=0.001`}>
    <track kind="captions" />
  </video>
)

export default VideoPlayer
