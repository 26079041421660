import * as api from './api'

export const login = async (data) => api.post('/auth/login', data)

export const resetPassword = async (data) =>
  api.post('/auth/reset-password', data)

export const verify = async (data) => api.post('/auth/verify', data)

export const sendVerificationEmail = async (data) =>
  api.post('/auth/verify/email', data)

export const recovery = async (data) => api.post('/auth/recovery', data)

export const sendRecoveryEmail = async (data) =>
  api.post('/auth/recovery/email', data)
