import { useQuery } from 'react-query'
import { getResultAnalyzes } from 'api/result.api'
import { GetListTypes } from 'types'

export default ({ page, size, order, sort }: GetListTypes) => {
  // prettier-ignore
  const mutationKey = `results${page ? `-page-${page}` : ''}${size ? `-size-${size}` : ''}${sort ? `-sort-${sort}` : ''}${order ? `-order-${order}` : ''}`

  return useQuery([mutationKey], () =>
    getResultAnalyzes({ page, size, sort, order })
  )
}
