/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react'
import { Form, FormInstance, InputNumber } from 'antd'
import { getFormatter } from 'utils/getFormatter'
import { getParserFloat } from 'utils/getParserFloat'
import getLocaleNumberFormat from 'utils/getLocaleNumberFormat'

import Caption from '../caption'

type MotilityTableProps = {
  form: FormInstance
  rowHeaders: string[]
}

const motileSpermSizeReference = 40
const progressiveMotilityReference = 25

const MotilityTable: React.FC<MotilityTableProps> = ({ form, rowHeaders }) => {
  // const { localeValueFormatter, localeParserFormatter } =
  //   useLocaleNumberInputFormatters()
  const volume = Form.useWatch('volume', form)
  const totalSperm = Form.useWatch('totalSperm', form)
  const progressiveMotility = Form.useWatch('progressiveMotility', form)
  const curvilinearMotility = Form.useWatch('curvilinearMotility', form)
  const nonProgressiveMotility = Form.useWatch('nonProgressiveMotility', form)
  const totalMotileSpermPercentage = Form.useWatch(
    'totalMotileSpermPercentage',
    form
  )

  const immotileSpermPercentage = Form.useWatch('immotileSpermPercentage', form)

  // MOTILE SPERM SIZE PERCENTAGE
  const totalMotilePercentage = useMemo(
    () =>
      Number(progressiveMotility) +
      Number(curvilinearMotility) +
      Number(nonProgressiveMotility),
    [progressiveMotility, curvilinearMotility, nonProgressiveMotility]
  )
  useEffect(() => {
    form.setFieldsValue({
      totalMotileSpermPercentage: getLocaleNumberFormat(
        totalMotilePercentage.toFixed(2)
      ),
    })
  }, [progressiveMotility, curvilinearMotility, nonProgressiveMotility])

  // MOTILE SPERM SIZE CONCENTRATION
  useEffect(() => {
    const calculatedMotileSpermSizeConc =
      (totalSperm * totalMotilePercentage) / 100
    form.setFieldsValue({
      motileSpermSizeConcentration: calculatedMotileSpermSizeConc.toFixed(2),
    })
  }, [totalMotileSpermPercentage, totalSperm])

  // PROGRESSIVE MOTILITY CONCENTRATION
  useEffect(() => {
    const calculatedProgressiveMotilityConcentration =
      (totalSperm * progressiveMotility) / 100

    form.setFieldsValue({
      progressiveMotilityConcentration: getLocaleNumberFormat(
        calculatedProgressiveMotilityConcentration.toFixed(2)
      ),
    })
  }, [progressiveMotility, totalSperm])

  // CURVILINEAR MOTILITY CONCENTRATION
  useEffect(() => {
    const calculatedCurvilinearMotilityConcentration =
      (totalSperm * curvilinearMotility) / 100

    form.setFieldsValue({
      curvilinearMotilityConcentration: getLocaleNumberFormat(
        calculatedCurvilinearMotilityConcentration.toFixed(2)
      ),
    })
  }, [curvilinearMotility, totalSperm])

  // NONPROGRESSIVE MOTILITY CONCENTRATION
  useEffect(() => {
    const calculatedNonProgressiveMotilityConcentration =
      (totalSperm * nonProgressiveMotility) / 100

    form.setFieldsValue({
      nonProgressiveMotilityConcentration: getLocaleNumberFormat(
        calculatedNonProgressiveMotilityConcentration.toFixed(2)
      ),
    })
  }, [nonProgressiveMotility, totalSperm])

  // IMMOTILE PERCENTAGE
  useEffect(() => {
    const calculatedImmotilePercentage = 100 - totalMotilePercentage

    form.setFieldsValue({
      immotileSpermPercentage: getLocaleNumberFormat(
        calculatedImmotilePercentage.toFixed(2)
      ),
    })
  }, [progressiveMotility, curvilinearMotility, nonProgressiveMotility])

  // IMMOTILE CONCENTRATION
  useEffect(() => {
    const calculatedImmotilePercentage = 100 - totalMotilePercentage
    const calculatedImmotileConcentration =
      (totalSperm * calculatedImmotilePercentage) / 100

    form.setFieldsValue({
      immotileSperm: calculatedImmotileConcentration.toFixed(2),
    })
  }, [totalSperm, immotileSpermPercentage])

  return (
    <>
      <Caption>Motility (WHO4)</Caption>
      <table>
        <tbody>
          <tr>
            <td> </td>
            {rowHeaders.map((header, index) => (
              <th
                scope="col"
                className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 pl-2"
                key={index}
              >
                {header}
              </th>
            ))}
          </tr>
        </tbody>
        <tbody>
          {/* ============================================================= */}
          <tr>
            <th
              scope="row"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
            >
              Motile Sperm Size
            </th>
            <td>
              <Form.Item name={['motileSpermSizeConcentration']}>
                <InputNumber
                  formatter={(target) =>
                    getFormatter(target, /\B(?=(\d{3})+(?!\d))/g, '.')
                  }
                  disabled
                />
              </Form.Item>
            </td>
            <td>
              <Form.Item
                name={['totalMotileSpermPercentage']}
                rules={[
                  {
                    message: 'Please enter value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled
                  step=".01"
                  min={Number(0)}
                  max={Number(100)}
                  placeholder="Motile Sperm Size Percentage"
                  formatter={(target) => getFormatter(target, /\./g, ',')}
                  parser={(target) => getParserFloat(target, /,/g, '.')}
                />
              </Form.Item>
            </td>
            <td className="px-4 py-2">
              {getFormatter((1 / volume).toPrecision(3), '.', ',')}
            </td>
            <td className="px-4 py-2">{`> ${motileSpermSizeReference}%`}</td>
          </tr>
          {/* ============================================================= */}
          <tr>
            <th
              scope="row"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px]  text-left p-1"
            >
              Progressive Motility (Type A)
            </th>
            <td>
              <Form.Item
                name={['progressiveMotilityConcentration']}
                rules={[
                  {
                    message: 'Please enter value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  formatter={(target) => getFormatter(target, /,/g, '.')}
                  disabled
                />
              </Form.Item>
            </td>
            <td className="flex items-center">
              <Form.Item
                name={['progressiveMotility']}
                rules={[
                  {
                    message: 'Please enter progressive motility value.',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  min={Number(0)}
                  max={Number(100)}
                  step=".01"
                  placeholder="Progressive Motility Percentage"
                  formatter={(target) => getFormatter(target, /\./g, ',')}
                  parser={(target) => getParserFloat(target, /,/g, '.')}
                />
              </Form.Item>
            </td>
            <td className="px-4 py-2"> </td>
            <td className="px-4 py-2">{`> ${progressiveMotilityReference}%`}</td>
          </tr>
          {/* ============================================================= */}
          <tr>
            <th
              scope="row"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
            >
              Curvilinear Motility (Type B)
            </th>
            <td>
              <Form.Item
                name={['curvilinearMotilityConcentration']}
                rules={[
                  {
                    message: 'Please enter curvilinear motility value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  formatter={(target) => getFormatter(target, /,/g, '.')}
                  disabled
                />
              </Form.Item>
            </td>
            <td>
              <Form.Item
                name={['curvilinearMotility']}
                rules={[
                  {
                    message: 'Please enter curvilinear motility value.',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  step=".01"
                  min={Number(0)}
                  max={Number(100)}
                  placeholder="Curvilinear Motility Percentage"
                  formatter={(target) => getFormatter(target, /\./g, ',')}
                  parser={(target) => getParserFloat(target, /,/g, '.')}
                />
              </Form.Item>
            </td>
            <td className="px-4 py-2"> </td>
          </tr>
          {/* ============================================================= */}
          <tr>
            <th
              scope="row"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
            >
              Non Progressive (Type C)
            </th>
            <td>
              <Form.Item
                name={['nonProgressiveMotilityConcentration']}
                rules={[
                  {
                    message: 'Please enter curvilinear motility value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  formatter={(target) => getFormatter(target, /,/g, '.')}
                  disabled
                />
              </Form.Item>
            </td>
            <td>
              <Form.Item
                name={['nonProgressiveMotility']}
                rules={[
                  {
                    message: 'Please enter non progressive motility value.',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  step=".01"
                  placeholder="Non-Progressive Percentage"
                  min={Number(0)}
                  max={Number(100)}
                  formatter={(target) => getFormatter(target, /\./g, ',')}
                  parser={(target) => getParserFloat(target, /,/g, '.')}
                />
              </Form.Item>
            </td>
            <td className="px-4 py-2"> </td>
          </tr>
          {/* ============================================================= */}
          <tr>
            <th
              scope="row"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
            >
              Immotile (Type D)
            </th>
            <td>
              <Form.Item
                name={['immotileSperm']}
                rules={[
                  {
                    message: 'Please enter immotile sperm value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  formatter={(target) =>
                    getFormatter(target, /\B(?=(\d{3})+(?!\d))/g, '.')
                  }
                  disabled
                />
              </Form.Item>
            </td>
            <td>
              <Form.Item
                name={['immotileSpermPercentage']}
                rules={[
                  {
                    message: 'Please enter immotile sperm value!',
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  disabled
                  min={Number(0)}
                  max={Number(100)}
                  step={0.01}
                  formatter={(target) => getFormatter(target, /\./g, ',')}
                  parser={(target) => getParserFloat(target, /,/g, '.')}
                />
              </Form.Item>
            </td>
            <td className="px-4 py-2"> </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default MotilityTable
