import { GetListTypes, Product } from 'types'
import * as api from './api'

export const getProducts = async ({
  page,
  size,
  sort,
  order,
}: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/product${isQueryParam ? query : ''}`)
}

export const getProduct = async (id: string) => api.get(`/product/${id}`)

export const updateProduct = async (id: string, data: Product) =>
  api.put(`/product/${id}`, data)

export const deleteProduct = async (id: string) => api.del(`/product/${id}`)
