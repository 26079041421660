import { useQuery } from 'react-query'
import { getDoctors } from 'api/doctor.api'
import { Doctor, GetListTypes, PageableResponseType } from 'types'

export default ({ page, size, sort, order }: GetListTypes) => {
  // prettier-ignore
  const mutationKey = `results${page ? `-page-${page}` : ''}${size ? `-size-${size}` : ''}${sort ? `-sort-${sort}` : ''}${order ? `-order-${order}` : ''}`

  return useQuery<PageableResponseType<Doctor[]>>([mutationKey], () =>
    getDoctors({ page, size, sort, order })
  )
}
