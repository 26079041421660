import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'common/components/buttons/button'
import FormModal from 'common/components/modals/formModal'
import Input from 'common/components/inputs/input'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import useShareResult from 'hooks/results/useShareResult'
import { toast } from 'react-toastify'

const ShareForm = () => {
  const methods = useFormContext()
  const {
    formState: { errors },
    register,
  } = methods

  return (
    <div>
      <Input
        label="Email"
        requiredField
        placeholder="test@test.com"
        type="email"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors.email?.message}
        {...register('email', {
          required: 'Please enter an email to share the result.',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please enter a valid email.',
          },
        })}
      />
    </div>
  )
}

type Props = {
  id: string
}

const ShareResultCell: React.FC<Props> = ({ id }) => {
  const { mutateAsync, isLoading } = useShareResult(id)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = ({ email }: any) => {
    mutateAsync({ email }).then(() => {
      toast('Result successfully sent.', {
        type: 'success',
      })
    })
    setIsModalOpen(false)
  }

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        status={isLoading ? 'fetching' : undefined}
        onClick={(e) => {
          e.stopPropagation()
          setIsModalOpen(true)
        }}
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faShare} />
          <p>Share Result</p>
        </div>
      </Button>
      <FormModal
        form={<ShareForm />}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        title="Share Result with Email"
        acceptButtonCaption="Send"
        rejectButtonCaption="Cancel"
        onSubmit={handleSubmit}
      />
    </div>
  )
}

export default ShareResultCell
