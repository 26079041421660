import React from 'react'

type Props = {
  children: React.ReactNode
}

const PublicLayout: React.FC<Props> = ({ children }) => (
  <div className="h-screen">
    <p className="p-4 bg-primary-500 text-white">SpermCell</p>
    <div className="flex justify-center items-center h-[calc(100vh-56px)] overflow-y-auto">
      {children}
    </div>
  </div>
)

export default PublicLayout
