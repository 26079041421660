import { useQuery } from 'react-query'
import { getQRCodes } from 'api/qrCode.api'
import { GetListTypes } from 'types'

export default ({ page, size, sort, order }: GetListTypes) => {
  // prettier-ignore
  const mutationKey = `results${page ? `-page-${page}` : ''}${size ? `-size-${size}` : ''}${sort ? `-sort-${sort}` : ''}${order ? `-order-${order}` : ''}`

  return useQuery([mutationKey], () => getQRCodes({ page, size, sort, order }))
}
