import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import ReactModal from 'react-modal'

import { SubmitFormTypes } from 'types'
import Button from '../buttons/button'
import SubmitButton from '../buttons/submitButton'
import { customStyles, STATUSES } from './modalContants'

// TODO types
type FormModalProps = {
  data?: SubmitFormTypes
  isOpen: boolean
  closeModal: () => void
  form: React.ReactNode
  status?: 'danger' | 'success' | 'warning' | 'primary'
  onSubmit: (arg: SubmitFormTypes) => void
  onReject?: () => void
  acceptButtonCaption?: string
  rejectButtonCaption?: string
  title?: string
}

const FormModal: React.FC<FormModalProps> = ({
  form,
  data,
  closeModal,
  title,
  acceptButtonCaption,
  rejectButtonCaption,
  status = 'primary',
  onSubmit,
  onReject = () => {},
  isOpen,
}) => {
  const methods = useForm<SubmitFormTypes>()

  useEffect(() => {
    if (data) {
      methods.reset(data)
    }
  }, [data])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={(e) => {
        e.stopPropagation()
        // methods.reset()
        closeModal()
      }}
      style={customStyles}
      ariaHideApp={false}
      overlayClassName="modalOverlay"
    >
      <div className="flex flex-col gap-4 h-full">
        <div className="flex items-center gap-2 p-6 border border-b-[1px] border-slate-200">
          {STATUSES[status].icon}
          <h2 className="text-black text-xl">{title}</h2>
        </div>
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-6 text-gray-700"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="px-6">{form}</div>
            <div className="flex justify-end gap-5 bg-slate-100 p-6">
              {rejectButtonCaption && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    onReject()
                    closeModal()
                  }}
                  variant="outlined"
                  color={status}
                >
                  {rejectButtonCaption}
                </Button>
              )}
              <SubmitButton
                onClick={(e) => {
                  e.stopPropagation()
                }}
                variant="contained"
                color={status}
                className="w-fit"
              >
                {acceptButtonCaption}
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </ReactModal>
  )
}

FormModal.defaultProps = {
  data: undefined,
  status: 'primary',
  title: '',
  acceptButtonCaption: '',
  rejectButtonCaption: '',
  onReject: () => {},
}

export default FormModal
