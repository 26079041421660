import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

type StatusCellProps = {
  status: boolean | string
}

const StatusCell: React.FC<StatusCellProps> = ({ status }) =>
  status ? (
    <FontAwesomeIcon icon={faCheck} />
  ) : (
    <FontAwesomeIcon icon={faXmark} />
  )

export default StatusCell
