import React from 'react'

import { toast } from 'react-toastify'
import DeleteCell from 'common/components/Table/deleteCell'
import { useDeleteCasaAnalyze } from 'hooks/casaResult/casaAnalyzeResult'

type Props = {
  id: string
}

const RemoveResultCell: React.FC<Props> = ({ id }) => {
  const { mutateAsync, isSuccess } = useDeleteCasaAnalyze()

  const onClick = () => {
    mutateAsync(id).then(() => {
      toast('Result successfully removed.', {
        type: 'error',
      })
    })
  }

  return (
    <div>
      <DeleteCell
        modalTitle="Delete User"
        modalContent="Are you sure you want to delete result? This action cannot be undone."
        handleDelete={onClick}
        isSuccess={isSuccess}
      />
    </div>
  )
}

export default RemoveResultCell
