import React from 'react'
import clsx from 'clsx'

const TYPES = {
  error: 'text-danger-500',
  success: 'text-success-500',
  warning: 'text-warning-500',
}

type AlertProps = {
  type: 'error' | 'success' | 'warning'
  message: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  className?: string
}

const Alert: React.FC<AlertProps> = ({
  type,
  message,
  startAdornment,
  endAdornment,
  className,
}) => (
  <div className="relative">
    {(startAdornment || endAdornment) && (
      <span className={clsx('absolute inset-y-0 flex items-center')}>
        {startAdornment || endAdornment}
      </span>
    )}
    <p role="alert" className={clsx('text-sm', TYPES[type], className)}>
      <span>{message}</span>
    </p>
  </div>
)

Alert.defaultProps = {
  startAdornment: null,
  endAdornment: null,
  className: '',
}

export default Alert
