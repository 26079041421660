import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useForgotPassword from 'hooks/auth/useForgotPassword'

import PublicLayout from 'common/layouts/public.layout'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Link from 'common/components/link'

const ForgotPasswordPage = () => {
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useForgotPassword()

  // TODO: data type any
  const onSubmit = (data: any) => mutate(data)

  useEffect(() => {
    if (error instanceof Error) {
      toast(error.message, { type: 'error' })
    }
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      toast('Recovery mail sent!', { type: 'success' })
    }
  }, [isSuccess])

  return (
    <PublicLayout>
      <div className="flex flex-col p-16 mx-auto my-12 space-y-6 tablet:rounded-3xl shadow-lg bg-slate-50 w-full tablet:w-[544px]">
        <span className="space-y-2">
          <h1 className="text-2xl">Forgot Password?</h1>
          <p>
            Enter your registered email, so we can send you a recovery link.
          </p>
        </span>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <Input
              label="Email"
              placeholder="test@test.com"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={methods.formState.errors?.email?.message}
              {...methods.register('email', {
                required: 'Please enter an email.',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email.',
                },
              })}
            />
            <SubmitButton status={isLoading ? 'disabled' : ''}>
              Send Activation Code
            </SubmitButton>
            <Link href="/login" variant="outlined">
              Go Back to Login
            </Link>
          </form>
        </FormProvider>
      </div>
    </PublicLayout>
  )
}

export default ForgotPasswordPage
