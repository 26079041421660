import React from 'react'

import Table from 'common/components/Table/table'
import 'antd/dist/antd.less'
import { objectType } from 'types'

// TODO: update types
type TableViewProps = {
  title: string
  isLoading: boolean
  columns: Array<objectType>
  data: any
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (id: string) => void
  actionButton?: React.ReactNode
  totalCount: number
  handleTableChange?: (pagination: any, filters: any, sorter: any) => void
}

const TableView: React.FC<TableViewProps> = ({
  title,
  isLoading,
  columns,
  data,
  onItemClick = () => {},
  actionButton,
  totalCount,
  handleTableChange,
}) => (
  <div className="flex flex-col gap-4">
    <div className="flex justify-between items-center">
      <h1 className="text-lg">{title}</h1>
      {actionButton && actionButton}
    </div>
    <Table
      columns={columns}
      data={data}
      onItemClick={onItemClick}
      isLoading={isLoading}
      totalCount={totalCount}
      handleTableChange={handleTableChange}
    />
  </div>
)

TableView.defaultProps = {
  onItemClick: () => {},
  actionButton: null,
  handleTableChange: () => {},
}

export default TableView
