/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify'

import useProductColumns from 'hooks/products/useProductColumns'
import useProduct from 'hooks/products/useProduct'
import useProducts from 'hooks/products/useProducts'
import useUpdateProduct from 'hooks/products/useUpdateProduct'
import useHandleTableChange from 'hooks/useHandleTableChange'

import AppLayout from 'common/layouts/app.layout'
import TableView from 'common/views/table.view'
import EditItemModal from 'common/views/editItemModal'
import ProductForm from 'common/views/product/productForm'
import statusSelectValuesConversion from 'constants/status_select_values'
import { Product } from 'types'

const ProductPage = () => {
  const { page, size, order, handleTableChange } = useHandleTableChange()

  const { data, isLoading, refetch } = useProducts({
    page: Number(page) - 1,
    size,
    sort: 'createdDate,desc',
    order,
  })
  const columns = useProductColumns()

  // Edit Modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  const [productId, setProductId] = useState('')
  const { data: itemData } = useProduct(productId, {
    enabled: Boolean(productId),
  })

  const { mutateAsync } = useUpdateProduct(productId)

  const onSubmit = (formData: Product) => {
    const reqBody = {
      ...formData,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      active: statusSelectValuesConversion[formData.active],
    }

    mutateAsync(reqBody).then(() => {
      toast('Product information updated successfully', { type: 'success' })
      closeModal()
      refetch()
    })
  }

  const convertedItemData = useMemo(
    () => ({ ...itemData, active: String(itemData?.active) }),
    [itemData]
  ) as Product

  return (
    <>
      <AppLayout>
        <TableView
          title="Products"
          columns={columns}
          data={data?.content}
          isLoading={isLoading}
          onItemClick={(id) => {
            setIsModalOpen(true)
            setProductId(id)
          }}
          handleTableChange={handleTableChange}
          totalCount={data?.totalElements || 0}
        />
      </AppLayout>
      <EditItemModal
        isOpen={isModalOpen}
        itemData={convertedItemData}
        closeModal={closeModal}
        title="Edit Product"
        acceptButtonCaption="Update Product"
        form={<ProductForm />}
        onSubmit={onSubmit}
      />
    </>
  )
}
export default ProductPage
