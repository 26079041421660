import React from 'react'

import { Col, Row, DatePicker, Form, FormInstance, Input } from 'antd'

type PatientProps = {
  form: FormInstance
}

const PatientTable: React.FC<PatientProps> = ({ form }) => {
  const type = Form.useWatch('type', form)
  const patient = Form.useWatch('patient', form)

  return (
    <div className="mt-4">
      <h2 className="text-lg font-bold">Patient Analysis</h2>
      <Row className="mt-4" wrap>
        {/* ---- COL -1 ---- */}
        <Col span={type === 'HUMAN' ? 11 : 24}>
          <Form.Item name={['patient', 'name']} label="Name">
            <Input />
          </Form.Item>
          {type === 'HUMAN' && (
            <>
              <Form.Item
                name={['patient', 'email']}
                label="E-mail"
                rules={[
                  {
                    message: 'E-mail must be entered!',
                    required: patient?.email,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={['patient', 'birthDate']} label="Birth date">
                <DatePicker className="w-full" />
              </Form.Item>
            </>
          )}

          {type === 'BULL' && (
            <Form.Item name={['patient', 'bovine']} label="Bovine">
              <Input className="w-full" />
            </Form.Item>
          )}
        </Col>
        {/* ---- COL -2 ---- */}
        <Col
          offset={type === 'HUMAN' ? 2 : 0}
          span={type === 'HUMAN' ? 11 : 24}
        >
          {type === 'HUMAN' && (
            <>
              <Form.Item name={['patient', 'surname']} label="Surname">
                <Input />
              </Form.Item>
              <Form.Item
                name={['patient', 'telephone']}
                label="Telephone number"
                rules={[
                  {
                    message: 'Telephone number must be entered!',
                    required: !patient?.email,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['patient', 'dietDuration']}
                label="Abstinence Days"
              >
                <Input type="number" />
              </Form.Item>
            </>
          )}
          {type === 'BULL' && (
            <Form.Item name={['patient', 'serialNumber']} label="Serial Number">
              <Input className="w-full" />
            </Form.Item>
          )}
        </Col>
      </Row>
    </div>
  )
}
export default PatientTable
