import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import Modal from 'common/components/modals/modal'
import Button from 'common/components/buttons/button'

type DeleteCellProps = {
  handleDelete?: () => void
  modalTitle: string
  modalContent: string | React.ReactNode
  noText?: boolean
  isSuccess: boolean
}

const DeleteCell: React.FC<DeleteCellProps> = ({
  handleDelete = () => {},
  modalTitle,
  modalContent,
  noText,
  isSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
  }, [isSuccess])

  return (
    <Button
      endAdornment={<FontAwesomeIcon icon={faTrash} />}
      onClick={(e) => {
        e.stopPropagation()
        setIsModalOpen(true)
      }}
      variant="outlined"
      color="danger"
    >
      {!noText && <p>Delete</p>}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={modalTitle}
        content={modalContent}
        status="danger"
        onAccept={handleDelete}
        onReject={closeModal}
        acceptButtonCaption="Delete"
        rejectButtonCaption="Cancel"
      />
    </Button>
  )
}

DeleteCell.defaultProps = {
  handleDelete: () => {},
  noText: false,
}

export default DeleteCell
