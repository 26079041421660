import React, { useState } from 'react'
import clsx from 'clsx'

import Sidebar from 'common/components/sidebar'
import Header from 'common/components/header'

type Props = {
  children: React.ReactNode
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <div className="flex flex-col">
      <Header
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <div className="flex h-[calc(100vh-60px)]">
        <Sidebar
          className={
            (clsx('flex flex-col overflow-y-auto'),
            isMobileMenuOpen
              ? 'flex flex-col'
              : 'hidden tablet:flex tablet:flex-col')
          }
          onItemClick={() => setIsMobileMenuOpen(false)}
        />
        <main
          className={clsx(
            'w-full h-[calc(100vh-60px)] overflow-y-auto flex flex-col'
          )}
        >
          <div
            className={clsx(
              'py-6 px-8 flex flex-col gap-6',
              isMobileMenuOpen && 'hidden tablet:flex tablet:flex-col'
            )}
          >
            {children}
          </div>
        </main>
      </div>
    </div>
  )
}

export default AppLayout
