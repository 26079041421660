import React from 'react'
import ReactModal from 'react-modal'

import { customStyles, STATUSES } from './modalContants'
import Button from '../buttons/button'

type ModalProps = {
  closeModal: () => void
  title?: string
  content?: string | React.ReactNode
  status?: 'danger' | 'success' | 'warning' | 'primary'
  onAccept?: () => void
  onReject?: () => void
  acceptButtonCaption?: string
  rejectButtonCaption?: string
  isOpen: boolean
}

const Modal: React.FC<ModalProps> = ({
  title = '',
  content = '',
  status = 'success',
  onAccept = () => {},
  onReject = () => {},
  acceptButtonCaption,
  rejectButtonCaption,
  closeModal,
  isOpen,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={(e) => {
      e.stopPropagation()
      closeModal()
    }}
    style={customStyles}
    ariaHideApp={false}
    overlayClassName="modalOverlay"
  >
    <div className="flex flex-col h-full">
      <div className="flex items-center gap-2 p-6 border border-b-[1px] border-slate-200">
        {STATUSES[status].icon}
        <h2 className="text-black text-xl">{title}</h2>
      </div>
      <span className="text-gray-700 p-6">{content}</span>
    </div>
    <div className="flex justify-end bg-slate-100 p-4 gap-4">
      {rejectButtonCaption && (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            onReject()
            closeModal()
          }}
          variant="outlined"
          color={status}
        >
          {rejectButtonCaption}
        </Button>
      )}
      <Button
        onClick={(e) => {
          e.stopPropagation()
          onAccept()
          closeModal()
        }}
        variant="contained"
        color={status}
      >
        {acceptButtonCaption}
      </Button>
    </div>
  </ReactModal>
)

Modal.defaultProps = {
  status: 'primary',
  title: '',
  content: '',
  acceptButtonCaption: '',
  rejectButtonCaption: '',
  onAccept: () => {},
  onReject: () => {},
}

export default Modal
