/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Form, InputNumber } from 'antd'
import { getFormatter } from 'utils/getFormatter'
import { getParserFloat } from 'utils/getParserFloat'
import Caption from '../caption'

type VelocityTableProps = {
  rowHeaders: string[]
}

const VelocityTable: React.FC<VelocityTableProps> = ({ rowHeaders }) => (
  <>
    <Caption>Velocity (WH04)</Caption>
    <table>
      <tbody>
        <tr>
          <td> </td>
          {rowHeaders.map((header, index) => (
            <th
              scope="col"
              className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
              key={index}
            >
              {header}
            </th>
          ))}
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th
            scope="row"
            className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
          >
            Curvilinear Velocity - VLC (m/s)
          </th>
          <td>
            <Form.Item
              name={['vclMin']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step={0.1}
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vclMax']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
                step=".01"
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vclMean']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td className="px-4 py-2"> </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
          >
            Average Path Velocity - VAP (m/s)
          </th>
          <td>
            <Form.Item
              name={['vapMin']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vapMax']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vapMean']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td className="px-4 py-2"> </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1"
          >
            Straight Line Velocity - VSL (m/s)
          </th>
          <td>
            <Form.Item
              name={['vslMin']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vslMax']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['vslMean']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td className="px-4 py-2"> </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="bg-primary-50 bg-opacity-40 border-primary-50 border-opacity-60 border-[1px] text-left p-1 "
          >
            Progression(m)
          </th>
          <td>
            <Form.Item
              name={['progMin']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['progMax']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td>
            <Form.Item
              name={['progMean']}
              rules={[
                {
                  message: 'Please enter value!',
                  required: true,
                },
              ]}
            >
              <InputNumber
                step=".01"
                formatter={(target) => getFormatter(target, /\./g, ',')}
                parser={(target) => getParserFloat(target, /,/g, '.')}
              />
            </Form.Item>
          </td>
          <td className="px-4 py-2"> </td>
        </tr>
      </tbody>
    </table>
  </>
)

export default VelocityTable
