import React from 'react'

import { Col, Row, Form, Select, FormInstance } from 'antd'
import useDoctors from 'hooks/doctors/useDoctors'
import { Option } from 'antd/lib/mentions'

type DoctorProps = {
  form: FormInstance
}

const labelOptions = {
  HUMAN: 'Doctor',
  BULL: 'Veterinarian',
}

const DoctorTable: React.FC<DoctorProps> = ({ form }) => {
  const type: 'HUMAN' | 'BULL' = Form.useWatch('type', form)

  const { data } = useDoctors({
    page: 0,
    size: '99999',
    sort: 'createdDate,desc',
  })

  return (
    <div>
      <h1 className="text-lg font-bold text-black">
        {labelOptions[type]} Information
      </h1>
      <Row className="flex mt-4">
        <Col span={24}>
          <Form.Item
            name={['doctorId']}
            label={labelOptions[type]}
            className="flex-1"
            style={{ marginTop: '4px' }}
          >
            <Select>
              {data?.content.map((doctor) => (
                <Option key={doctor.id} value={doctor.id}>
                  {doctor.name} {doctor.surname}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default DoctorTable
