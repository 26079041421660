import React from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

const SIZES = {
  large: 'px-8 py-3',
  medium: 'px-4 py-2',
  small: 'px-2 py-1',
}

const COLORS = {
  contained: {
    primary:
      'bg-primary-500 border border-primary-500 hover:border-primary-600 hover:bg-primary-600 text-white',
    secondary:
      'bg-secondary-500 border border-secondary-500 hover:border-secondary-600 hover:bg-secondary-600 text-white',
    danger:
      'bg-danger-500 border border-danger-500 hover:border-danger-600 hover:bg-danger-600 text-white',
    success:
      'bg-success-500 border border-success-500 hover:border-success-600 hover:bg-success-600 text-white',
    warning:
      'bg-warning-500 border border-warning-500 hover:border-warning-600 hover:bg-warning-600 text-white',
    gray: 'bg-gray-500 border border-gray-500 hover:border-gray-600 hover:bg-gray-600 text-white',
    transparent: '',
  },
  outlined: {
    primary:
      'border border-primary-500 focus:border-primary-500 hover:bg-primary-500 text-primary-500 hover:text-white',
    secondary:
      'border border-secondary-500 focus:border-secondary-500 hover:bg-secondary-500 text-secondary-500 hover:text-white',
    danger:
      'border border-danger-500 focus:border-danger-500 hover:bg-danger-500 text-danger-500 hover:text-white',
    success:
      'border border-success-500 focus:border-success-500 hover:bg-success-500 text-success-500 hover:text-white',
    warning:
      'border border-warning-500 focus:border-warning-500 hover:bg-warning-500 text-warning-500 hover:text-white',
    gray: 'border border-gray-500 focus:border-gray-500 hover:bg-gray-500 text-gray-500 hover:text-white',
    transparent:
      'border border-light focus:border-light hover:border-gray-500 text-primary-500',
  },
  dashed: {
    primary: 'outline-dashed text-primary-500',
    secondary: 'outline-dashed text-secondary-500',
    danger: 'outline-dashed text-danger-500',
    success: 'outline-dashed text-success-500',
    warning: 'outline-dashed text-warning-500',
    gray: 'outline-dashed text-gray-500',
    transparent: '',
  },
  default: {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    danger: 'text-danger-500',
    success: 'text-success-500',
    warning: 'text-warning-500',
    gray: 'text-secondary-900',
    transparent: '',
  },
}

type LinkProps = {
  children: string | React.ReactNode
  href: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  variant?: 'contained' | 'outlined' | 'default' | 'dashed'
  color?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'gray'
    | 'transparent'
  size?: 'large' | 'medium' | 'small'
  className?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
  isExternal?: boolean
}

const Link: React.FC<LinkProps> = ({
  children,
  href,
  variant = 'default',
  color = 'primary',
  size = 'medium',
  className,
  onClick,
  startAdornment,
  endAdornment,
  isExternal,
}) =>
  isExternal ? (
    <RouterLink
      to={href}
      onClick={onClick}
      className={clsx(
        'flex justify-center items-center rounded-lg font-medium',
        COLORS[variant][color],
        variant !== 'default' && SIZES[size],
        variant === 'default' && 'hover:underline',
        className
      )}
      target="_blank"
    >
      <div className="flex items-center space-x-2">
        {startAdornment}
        {children}
        {endAdornment}
      </div>
    </RouterLink>
  ) : (
    <RouterLink
      to={href}
      onClick={onClick}
      className={clsx(
        'flex justify-center items-center rounded-lg font-medium',
        COLORS[variant][color],
        variant !== 'default' && SIZES[size],
        variant === 'default' && 'hover:underline',
        className
      )}
    >
      <div className="flex items-center space-x-2">
        {startAdornment}
        {children}
        {endAdornment}
      </div>
    </RouterLink>
  )

Link.defaultProps = {
  onClick: () => {},
  variant: 'default',
  color: 'primary',
  size: 'medium',
  className: '',
  startAdornment: null,
  endAdornment: null,
  isExternal: false,
}

export default Link
