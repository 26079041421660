import React from 'react'

import { ResultResponse } from 'types'
import { Form, FormInstance, Input } from 'antd'
import dayjs from 'dayjs'

import Caption from './caption'
import AnalysisTable from './reportComponents/analysisTable'
import ConcentrationTable from './reportComponents/concentrationTable'
import MotilityTable from './reportComponents/motilityTable'
import PatientTable from './reportComponents/patientTable'
import VelocityTable from './reportComponents/velocityTable'
import DoctorTable from './reportComponents/doctorTable'

type DocumentProps = {
  form: FormInstance
  data: ResultResponse
}

const motilityRowHeaders = ['Concentration', '%', 'Conc./Ejac.', 'Reference']
const velocityRowHeaders = ['Minimum', 'Maximum', 'Average', 'Reference']

const ResultPDFDocument: React.FC<DocumentProps> = ({ form, data }) => (
  <div className="flex laptop:justify-center">
    <div className="flex flex-col gap-4 w-[800px] p-6">
      <div className="flex justify-between items-center border-b-[1px] border-primary-500">
        <p className="text-lg font-bold">Semen Analysis Report</p>
        <p>{dayjs(data.analyzeDate).format('DD/MM/YYYY')}</p>
      </div>
      <PatientTable form={form} />
      <DoctorTable form={form} />
      <AnalysisTable />
      <ConcentrationTable form={form} />
      <MotilityTable form={form} rowHeaders={motilityRowHeaders} />
      <VelocityTable rowHeaders={velocityRowHeaders} />
      <Caption>Description</Caption>
      <Form.Item name={['comment']}>
        <Input.TextArea />
      </Form.Item>
      <Caption>Analysis Views</Caption>
      <div className="flex w-full gap-4">
        <img
          src={data.imageOriginal.url}
          alt={data.imageOriginal.name}
          className="w-1/4"
        />
        <img
          src={data.imageWithoutBackground.url}
          alt={data.imageWithoutBackground.name}
          className="w-1/4"
        />
        <img
          src={data.imageThresholded.url}
          alt={data.imageThresholded.name}
          className="w-1/4"
        />
      </div>
    </div>
  </div>
)

export default ResultPDFDocument
