import React from 'react'
import { Table as AntTable } from 'antd'

import { objectType } from 'types'
import 'antd/dist/antd.less'

// TODO: update types
type TableProps = {
  isLoading: boolean
  columns: Array<objectType>
  data: any
  // eslint-disable-next-line no-unused-vars
  onItemClick?: (id: string) => void
  totalCount: number
  handleTableChange?: (pagination: any, filters: any, sorter: any) => void
}

const Table: React.FC<TableProps> = ({
  isLoading,
  columns,
  data,
  onItemClick = () => {},
  totalCount,
  handleTableChange,
}) => (
  <AntTable
    columns={columns}
    dataSource={data}
    onRow={(record) => ({
      onClick: () => onItemClick(record.id),
    })}
    rowKey={(record) => record.id}
    loading={isLoading}
    pagination={{
      defaultCurrent: 1,
      defaultPageSize: 10,
      showSizeChanger: true,
      total: totalCount,
      pageSizeOptions: ['10'],
    }}
    onChange={handleTableChange}
  />
)

Table.defaultProps = {
  onItemClick: () => {},
  handleTableChange: () => {},
}

export default Table
