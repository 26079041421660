import React, { useState } from 'react'

import Modal from 'common/components/modals/modal'
import Button from 'common/components/buttons/button'
import VideoPlayer from './videoPlayer'

type VideoContentProps = {
  data: {
    src: string
  }
}

const VideoContent: React.FC<VideoContentProps> = ({ data }) => (
  <div className="flex flex-col gap-4">
    <VideoPlayer data={data} />
  </div>
)

type Props = {
  url: string
}

const PreviewVideoCell: React.FC<Props> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation()
          setIsModalOpen(true)
        }}
      >
        <div className="flex items-center gap-1 text-xs">
          <p>Analysis Video</p>
        </div>
      </Button>
      <Modal
        content={<VideoContent data={{ src: url }} />}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        title="Analysis Video"
        acceptButtonCaption="Close"
      />
    </div>
  )
}

export default PreviewVideoCell
