import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.clear()
    setTimeout(() => {
      navigate('/')
    }, 1000)
  }, [])

  return (
    <h4>
      Bilinmeyen bir hata oluştu! Birazdan anasayfaya yönlendiriliyorsunuz.
    </h4>
  )
}

export default ErrorPage
