import { useMutation } from 'react-query'
import { updateResult } from 'api/result.api'

// TODO undefined
export default (id: string | undefined, options: any) =>
  useMutation(
    [`update-result-${id}`],
    (data: any) => updateResult(id, data),
    options
  )
