import { GetListTypes, User } from 'types'
import * as api from './api'

export const getUsers = async ({ page, size, sort, order }: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/user${isQueryParam ? query : ''}`)
}

export const getUser = async (id: string) => api.get(`/user/${id}`)

export const updateUser = async (id: string, data: User) =>
  api.put(`/user/${id}`, data)

export const createNewUser = async (data: User) => api.post('/user', data)

export const deleteUser = async (id: string) => api.del(`/user/${id}`)
