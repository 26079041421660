/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import useDoctors from 'hooks/doctors/useDoctors'
import useDoctorColumns from 'hooks/doctors/useDoctorColumns'
import useDoctor from 'hooks/doctors/useDoctor'
import useUpdateDoctor from 'hooks/doctors/useUpdateDoctor'
import useNewDoctor from 'hooks/doctors/useNewDoctor'
import useHandleTableChange from 'hooks/useHandleTableChange'

import AppLayout from 'common/layouts/app.layout'
import TableView from 'common/views/table.view'
import EditItemModal from 'common/views/editItemModal'
import FormModal from 'common/components/modals/formModal'
import DoctorForm from 'common/views/doctors/doctorForm'
import Button from 'common/components/buttons/button'
import statusSelectValuesConversion from 'constants/status_select_values'
import { Doctor } from 'types'

type Props = {
  refetch: () => void
}

const AddDoctorButton: React.FC<Props> = ({ refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  const { mutateAsync } = useNewDoctor()
  const onSubmit = (data: Doctor) =>
    mutateAsync(data).then(() => {
      toast('Doctor added successfully.', {
        type: 'success',
      })
      closeModal()
      refetch()
    })

  return (
    <Button onClick={() => setIsModalOpen(true)} variant="outlined">
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faPlus} />
        <p>Add Doctor</p>
      </div>
      <FormModal
        isOpen={isModalOpen}
        form={<DoctorForm />}
        closeModal={closeModal}
        // TODO
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit}
        acceptButtonCaption="Add"
        rejectButtonCaption="Cancel"
        title="Add New Doctor"
      />
    </Button>
  )
}

const DoctorPage = () => {
  const { page, size, handleTableChange } = useHandleTableChange()

  const {
    data,
    isLoading,
    refetch: refetchDoctors,
  } = useDoctors({ page: Number(page) - 1, size, sort: 'createdDate,desc' })
  const columns = useDoctorColumns({ refetchDoctors })

  // Edit Modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [doctorId, setDoctorId] = useState('')
  const closeModal = () => setIsModalOpen(false)

  const { data: itemData, refetch: refetchDoctor } = useDoctor(doctorId, {
    enabled: Boolean(doctorId),
  })
  const { mutateAsync } = useUpdateDoctor(doctorId)

  const onEditItemSubmit = (formData: Doctor) => {
    const reqBody = {
      ...formData,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      active: statusSelectValuesConversion[formData.active],
    }

    mutateAsync(reqBody).then(() => {
      toast('Doctor information updated successfully.', { type: 'success' })
      closeModal()
      refetchDoctors()
      // TODO check if this is necessary - it shouldn't be
      refetchDoctor()
    })
  }

  const convertedItemData = useMemo(
    () => ({ ...itemData, active: String(itemData?.active) }),
    [itemData]
  )

  return (
    <AppLayout>
      <TableView
        title="Doctors"
        isLoading={isLoading}
        data={data?.content}
        columns={columns}
        onItemClick={(id) => {
          setIsModalOpen(true)
          setDoctorId(id)
        }}
        actionButton={<AddDoctorButton refetch={refetchDoctors} />}
        totalCount={data?.totalElements || 0}
        handleTableChange={handleTableChange}
      />
      <EditItemModal
        isOpen={isModalOpen}
        itemData={convertedItemData}
        closeModal={closeModal}
        title="Edit Doctor"
        acceptButtonCaption="Update Doctor Information"
        form={<DoctorForm />}
        onSubmit={onEditItemSubmit}
      />
    </AppLayout>
  )
}

export default DoctorPage
