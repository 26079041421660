import React from 'react'
import { Select as AntSelect } from 'antd'
import clsx from 'clsx'

import Alert from './alert'

type OptionType = {
  value: string
  label: string
}

type SelectProps = {
  options: OptionType[]
  label?: string
  error?: string
  // eslint-disable-next-line no-unused-vars
  onChange: (arg: any) => void
  containerClassName?: string
  requiredField?: boolean
  id: string
  value: string | undefined
  showSearch?: boolean
  placeholder?: string
}

const { Option } = AntSelect

const Select: React.FC<SelectProps> = ({
  options,
  label,
  error,
  onChange,
  containerClassName,
  requiredField,
  id,
  value,
  showSearch = true,
  placeholder,
}) => (
  <div className={clsx('flex flex-col', containerClassName)}>
    {label && (
      <label htmlFor={id} className={clsx('text-gray-700 text-lg mb-2')}>
        <span>{label}</span>
        {requiredField && <span className="text-danger-600">*</span>}
      </label>
    )}
    <AntSelect
      onChange={onChange}
      value={value}
      showSearch={showSearch}
      placeholder={placeholder}
    >
      {options.map((option) => (
        <Option value={option.value} key={option.label}>
          {option.label}
        </Option>
      ))}
    </AntSelect>
    {error && <Alert type="error" message={error} />}
  </div>
)

Select.defaultProps = {
  containerClassName: '',
  requiredField: false,
  error: '',
  showSearch: true,
  placeholder: '',
  label: '',
}

export default Select
