import { useNavigate } from 'react-router-dom'

export default () => {
  const navigate = useNavigate()

  const signOut = () => {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userId')
    navigate('/')
  }

  return signOut
}
