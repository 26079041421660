import { Doctor, GetListTypes } from 'types'
import * as api from './api'

export const getDoctors = async ({ page, size, sort, order }: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/doctor${isQueryParam ? query : ''}`)
}

export const getDoctor = async (id: string) => api.get(`/doctor/${id}`)

export const createDoctor = async (data: Doctor) => api.post('/doctor', data)

export const updateDoctor = async (id: string, data: Doctor) =>
  api.put(`/doctor/${id}`, data)

export const deleteDoctor = async (id: string) => api.del(`/doctor/${id}`)
