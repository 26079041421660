import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'

import LoginPage from './login.page'
import ForgotPasswordPage from './forgotPassword.page'
import NewPasswordPage from './newPassword.page'

const AppRoutes = () => {
  const [token] = useLocalStorage('token')

  if (token) {
    return <Navigate to="/app" />
  }

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="password-reset/:code" element={<NewPasswordPage />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Routes>
  )
}

export default AppRoutes
