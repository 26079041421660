/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'

import useResultAnalyzes from 'hooks/results/useResultAnalyzes'
import useResultColumns from 'hooks/results/useResultColumns'
import useHandleTableChange from 'hooks/useHandleTableChange'

import AppLayout from 'common/layouts/app.layout'
import TableView from 'common/views/table.view'

const ResultPage = () => {
  const { page, size, order, handleTableChange } = useHandleTableChange()

  const { data, isLoading, refetch } = useResultAnalyzes({
    page: Number(page) - 1,
    size,
    sort: 'createdDate,desc',
    order,
  })
  const columns = useResultColumns()

  setTimeout(() => {
    refetch()
  }, 60000)
  return (
    <AppLayout>
      <TableView
        title="Results"
        columns={columns}
        data={data?.content}
        isLoading={isLoading}
        totalCount={data?.totalElements}
        handleTableChange={handleTableChange}
      />
    </AppLayout>
  )
}

export default ResultPage
