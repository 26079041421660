import { useQuery } from 'react-query'
import { getUsers } from 'api/user.api'
import { GetListTypes, PageableResponseType, User } from 'types'

export default ({ page, size, sort, order }: GetListTypes) => {
  // prettier-ignore
  const mutationKey = `results${page ? `-page-${page}` : ''}${size ? `-size-${size}` : ''}${sort ? `-sort-${sort}` : ''}${order ? `-order-${order}` : ''}`

  return useQuery<PageableResponseType<User[]>>([mutationKey], () =>
    getUsers({ page, size, sort, order })
  )
}
