/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable new-cap */
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSave } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { Form, Row } from 'antd'
import moment from 'moment'

import useUpdateResult from 'hooks/results/useUpdateResult'
import useUpdatePatient from 'hooks/patients/useUpdatePatient'
import useResult from 'hooks/results/useResult'

import Button from 'common/components/buttons/button'
import AppLayout from 'common/layouts/app.layout'
import ResultPDFDocument from 'common/views/results/resultPDF/resultPDFDocument'
import Spinner from 'common/components/spinner'
import SubmitButton from 'common/components/buttons/submitButton'
import ShareResultCell from 'common/views/results/shareResultCell'
import SendResultCell from 'common/views/results/sendResultCell'
import { ResultResponse, Patient } from 'types'
import { toast } from 'react-toastify'

type formDataType = Patient & ResultResponse

type SubmittedData = formDataType & {
  curvilinearMotilityConcentration: string
  motileSpermSizeConcentration: string
  nonProgressiveMotilityConcentration: string
  progressiveMotilityConcentration: string
  totalMotileSpermPercentage: number
  immotileSpermPercentage: number
}

const ResultPDFPage = () => {
  const [form] = Form.useForm()

  const { id: resultId } = useParams()
  const { data, refetch } = useResult(resultId, {
    enabled: Boolean(resultId),
  })

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        totalMotileSpermPercentage: data.motility,
        immotileSpermPercentage: data.immotility,
        motileSpermSizeConcentration: data.motileSperm,
        patient: {
          ...data.patient,
          birthDate: data.patient.birthDate
            ? moment(data.patient.birthDate)
            : null,
        },
        analyzeDate: moment(data.analyzeDate),
      })
    }
  }, [data])

  const { mutateAsync: mutateAsyncResult, isLoading: isLoadingUpdateResult } =
    useUpdateResult(resultId, {
      enabled: Boolean(resultId),
    })

  const { mutateAsync: mutateAsyncPatient, isLoading: isLoadingUpdatePatient } =
    useUpdatePatient(data?.patient.id)

  const onFinish = (formData: SubmittedData) => {
    const patientData = {
      ...formData.patient,
      birthDate: formData.patient.birthDate
        ? moment(formData.patient.birthDate).format('YYYY-MM-DD')
        : null,
    }

    const resultData = {
      doctorId: formData.doctorId,
      language: formData.language,
      type: formData.type,
      comment: formData.comment,
      volume: formData.volume,
      patientId: data.patient.id,
      curvilinearMotility: formData.curvilinearMotility,
      immotileSperm: formData.immotileSperm,
      curvilinearMotileSperm:
        formData.curvilinearMotilityConcentration.replaceAll(',', ''),
      immotility: formData.immotileSpermPercentage,
      motileSperm: formData.motileSpermSizeConcentration,
      motility: formData.totalMotileSpermPercentage,
      nonProgressiveMotility: formData.nonProgressiveMotility,
      nonProgressiveMotileSperm:
        formData.nonProgressiveMotilityConcentration.replaceAll(',', ''),
      progMax: formData.progMax,
      progMean: formData.progMean,
      progMin: formData.progMin,
      progressiveMotility: formData.progressiveMotility,
      progressiveMotileSperm:
        formData.progressiveMotilityConcentration.replaceAll(',', ''),
      totalSperm: formData.totalSperm,
      vapMax: formData.vapMax,
      vapMean: formData.vapMean,
      vapMin: formData.vapMin,
      vclMax: formData.vclMax,
      vclMean: formData.vclMean,
      vclMin: formData.vclMin,
      vslMax: formData.vslMax,
      vslMean: formData.vslMean,
      vslMin: formData.vslMin,
    }

    mutateAsyncPatient(patientData)
      .then(async () => {
        await mutateAsyncResult(resultData)
      })
      .then(() => {
        refetch()
        toast('Result successfully updated!', { type: 'success' })
      })
  }

  return (
    <AppLayout>
      {data ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name="patientReportDetails"
          scrollToFirstError
          initialValues={{ remember: true }} // TODO: I prepared initial values as empty object ,but in Sections, I declared them in Form.Item
        >
          <Row className="flex items-center justify-center gap-4">
            <SubmitButton
              status={
                isLoadingUpdateResult || isLoadingUpdatePatient
                  ? 'fetching'
                  : undefined
              }
              size="small"
              variant="outlined"
              className="w-fit"
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faSave} />
                Save Result
              </div>
            </SubmitButton>
            <Button
              size="small"
              variant="outlined"
              onClick={() => saveAs(data.pdf.url, 'result.pdf')}
            >
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faDownload} />
                <p>Download Result</p>
              </div>
            </Button>
            <ShareResultCell id={resultId || ''} />
            <SendResultCell id={resultId || ''} />
          </Row>
          <ResultPDFDocument data={data} form={form} />
        </Form>
      ) : (
        <Spinner />
      )}
    </AppLayout>
  )
}

export default ResultPDFPage
