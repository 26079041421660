import { InboxOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  UploadProps,
  message,
} from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { UploadFile } from 'antd/lib/upload/interface'
import AppLayout from 'common/layouts/app.layout'
import { useRunCasaAnalyze } from 'hooks/casaResult/casaAnalyzeResult'
import useDoctors from 'hooks/doctors/useDoctors'
import useProducts from 'hooks/products/useProducts'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

const NewAnalysis = () => {
  const [form] = Form.useForm()
  const { Dragger } = Upload
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const { data: products } = useProducts({
    page: 0,
    size: '4',
    sort: 'createdDate,desc',
    order: 'asc',
  })
  const { data: doctors } = useDoctors({
    page: 0,
    size: '20',
    sort: 'createdDate,desc',
    order: 'asc',
  })
  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Russian', value: 'ru' },
  ]

  const analysisAlgorithmOptions = [
    { label: 'YOLO', value: true },
    { label: 'CASA', value: false },
  ]

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log('File is Uploading')
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    beforeUpload: (file) => {
      const isMp4 = file.type === 'video/mp4'
      const hasData = file.size > 0
      if (!isMp4) {
        message.error(`${file.name} is not a mp4 file`)
        return isMp4 || Upload.LIST_IGNORE
      }
      if (!hasData) {
        message.error(`${file.name} does not contain any data!`)
        return hasData || Upload.LIST_IGNORE
      }
      setFileList([...fileList, file])

      return false
    },
    fileList,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  const { mutate: mutateRunCasaAnlyze } = useRunCasaAnalyze(() =>
    toast('Casa Result created successfully', { type: 'success' })
  )
  const onSubmit = (e: any) => {
    mutateRunCasaAnlyze({ ...e, file: fileList[0] })
  }
  return (
    <AppLayout>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h1 className="text-lg">New Analysis</h1>
        </div>
        <hr />
        <h1 className="text-lg font-bold text-black">Patient Information</h1>

        <hr />

        <Form form={form} layout="vertical" className="mt-2">
          <div className="grid grid-cols-2 gap-x-4">
            <FormItem
              label="Patient Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Patient Surname"
              name="surname"
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Patient Email"
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Patient Phone Number"
              name="phoneNumber"
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>
            <FormItem
              label="Patient Birth Date"
              name="birthDate"
              rules={[{ required: true, type: 'date' }]}
            >
              <Input
                type="date"
                placeholder={new Date().getTime().toString()}
              />
            </FormItem>
            <FormItem
              label="Abstinence Days"
              name="dietDuration"
              rules={[{ required: true, pattern: /^[0-9]+$/ }]}
            >
              <Input type="number" min={0} />
            </FormItem>
          </div>
        </Form>
        <Form form={form} layout="vertical" onFinish={(e) => onSubmit(e)}>
          <h1 className="text-lg font-bold text-black">Doctor Information</h1>
          <FormItem
            label="Select Doctor"
            name="doctorId"
            rules={[{ required: true }]}
          >
            <Select
              className="w-full"
              options={doctors?.content.map((element) => ({
                label: element.name,
                value: element.id,
              }))}
            />
          </FormItem>
          <h1 className="text-lg font-bold text-black">Analysis Information</h1>
          <FormItem
            label="Select Language"
            name="language"
            rules={[{ required: true }]}
          >
            <Select className="w-full" options={languageOptions} />
          </FormItem>
          <FormItem
            label="Barcode Id"
            name="barcodeId"
            rules={[{ required: true }]}
          >
            <Input placeholder="Enter barcode Id" />
          </FormItem>
          <div className="grid grid-cols-2 gap-x-4">
            <FormItem
              label="Select Sperm type"
              name="spermType"
              rules={[{ required: true }]}
            >
              <Select
                className="w-full"
                options={products?.content.map((element) => ({
                  label: element.name,
                  value: element.type,
                }))}
              />
            </FormItem>

            <FormItem
              label="Volume"
              name="volume"
              rules={[{ required: true, pattern: /[+-]?([0-9]*[.])?[0-9]+/ }]}
            >
              <InputNumber
                min={Number(0)}
                max={Number(100)}
                step={0.01}
                formatter={(value) => String(value).replace(/\./g, ',')}
                parser={(value) => parseFloat(String(value).replace(/,/g, '.'))}
              />
            </FormItem>
          </div>

          <div className="h-40">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag video to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibited from uploading
                company data or other banned files.
              </p>
            </Dragger>
          </div>

          <div className="mt-8">
            <Form.Item
              label="Algorithm for analysis"
              name="useYOLO"
              initialValue
            >
              <Select options={analysisAlgorithmOptions} />
            </Form.Item>
          </div>

          <Button type="primary" htmlType="submit" className="my-8">
            Submit
          </Button>
        </Form>
      </div>
    </AppLayout>
  )
}
export default NewAnalysis
