import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from 'common/components/inputs/input'
import Select from 'common/components/select'
import { STATUS_OPTIONS } from 'constants/select_constants'

const DoctorForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-5">
      <Input
        label="First Name"
        requiredField
        placeholder="First Name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.name?.message}
        {...register('name', {
          required: `Please enter doctor's first name`,
        })}
      />
      <Input
        label="Last Name"
        requiredField
        placeholder="Last Name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.surname?.message}
        {...register('surname', {
          required: `Please enter doctor's last name`,
        })}
      />
      <Input
        label="Email"
        placeholder="test@test.com"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.email?.message}
        requiredField
        {...register('email', {
          required: 'Please enter an email.',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please enter a valid email.',
          },
        })}
      />
      <Controller
        name="active"
        control={control}
        rules={{ required: 'Please select doctor status.' }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={STATUS_OPTIONS}
            id="status"
            label="Status"
            onChange={(val) => onChange(val)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={errors.active?.message}
            value={value && String(value)}
            placeholder="-"
            requiredField
          />
        )}
      />
    </div>
  )
}

export default DoctorForm
