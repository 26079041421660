import React from 'react'
import clsx from 'clsx'

const STATUSES = {
  fetching: 'opacity-50 cursor-wait',
  disabled: 'opacity-50 cursor-not-allowed',
}

const SIZES = {
  large: 'px-8 py-3',
  medium: 'px-4 py-2',
  small: 'px-2 py-1',
}

const COLORS = {
  contained: {
    primary:
      'bg-primary-500 border border-primary-500 hover:border-primary-600 hover:bg-primary-600 text-white',
    secondary:
      'bg-secondary-500 border border-secondary-500 hover:border-secondary-600 hover:bg-secondary-600 text-white',
    danger:
      'bg-danger-500 border border-danger-500 hover:border-danger-600 hover:bg-danger-600 text-white',
    success:
      'bg-success-500 border border-success-500 hover:border-success-600 hover:bg-success-600 text-white',
    warning:
      'bg-warning-500 border border-warning-500 hover:border-warning-600 hover:bg-warning-600 text-white',
    gray: 'bg-gray-500 border border-gray-500 hover:border-gray-600 hover:bg-gray-600 text-white',
    transparent: '',
  },
  outlined: {
    primary:
      'border border-primary-500 focus:border-primary-500 hover:bg-primary-500 text-primary-500 hover:text-white',
    secondary:
      'border border-secondary-500 focus:border-secondary-500 hover:bg-secondary-500 text-secondary-500 hover:text-white',
    danger:
      'border border-danger-500 focus:border-danger-500 hover:bg-danger-500 text-danger-500 hover:text-white',
    success:
      'border border-success-500 focus:border-success-500 hover:bg-success-500 text-success-500 hover:text-white',
    warning:
      'border border-warning-500 focus:border-warning-500 hover:bg-warning-500 text-warning-500 hover:text-white',
    gray: 'border border-gray-500 focus:border-gray-500 hover:bg-gray-500 text-gray-500 hover:text-white',
    transparent:
      'border border-light focus:border-light hover:bg-primary-600 text-white',
  },
  dashed: {
    primary: 'outline-dashed text-primary-500',
    secondary: 'outline-dashed text-secondary-500',
    danger: 'outline-dashed text-danger-500',
    success: 'outline-dashed text-success-500',
    warning: 'outline-dashed text-warning-500',
    gray: 'outline-dashed text-gray-500',
    transparent: '',
  },
  default: {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    danger: 'text-danger-500',
    success: 'text-success-500',
    warning: 'text-warning-500',
    gray: 'text-secondary-900',
    transparent: 'text-white',
  },
}

type ButtonProps = {
  children: React.ReactNode | string
  status?: 'fetching' | 'disabled'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  variant?: 'contained' | 'outlined' | 'default' | 'dashed'
  color?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'gray'
    | 'transparent'
  size?: 'large' | 'medium' | 'small'
  className?: string
  endAdornment?: React.ReactNode
  startAdornment?: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({
  children,
  status,
  onClick,
  variant = 'default',
  color = 'primary',
  size = 'medium',
  className,
  endAdornment,
  startAdornment,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx(
      'flex justify-center items-center rounded-lg font-medium',
      COLORS[variant][color],
      status && STATUSES[status],
      variant !== 'default' && SIZES[size],
      variant === 'default' && 'hover:underline',
      className
    )}
    disabled={status === 'disabled'}
  >
    <div className="flex items-center space-x-2">
      {startAdornment}
      {children}
      {endAdornment}
    </div>
  </button>
)

Button.defaultProps = {
  status: undefined,
  onClick: () => {},
  variant: 'contained',
  color: 'primary',
  size: 'medium',
  className: '',
  startAdornment: null,
  endAdornment: null,
}

export default Button
