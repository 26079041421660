import React, { useMemo } from 'react'
import dayjs from 'dayjs'

import DownloadQRCell from '../../common/views/qrCode/downloadQRCell'

const useQRCodeColumns = () => {
  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Date',
        dataIndex: 'modifiedDate',
        key: 'modifiedDate',
        // TODO: make it QR code data type
        render: (data: string) => <p>{dayjs(data).format('DD/MM/YYYY')}</p>,
      },
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'Download',
        dataIndex: 'file',
        key: 'file',
        render: (data: { url: string }) => <DownloadQRCell url={data?.url} />,
      },
    ],
    []
  )

  return columns
}

export default useQRCodeColumns
