import React from 'react'

import FormModal from 'common/components/modals/formModal'
import { SubmitFormTypes } from 'types'

type EditItemModalProps = {
  isOpen: boolean
  closeModal: () => void
  title: string
  acceptButtonCaption: string
  form: React.ReactNode
  onSubmit: (arg: any) => void
  // TODO
  itemData: SubmitFormTypes | undefined
}

const EditItemModal: React.FC<EditItemModalProps> = ({
  isOpen,
  closeModal,
  title,
  acceptButtonCaption,
  form,
  itemData,
  onSubmit,
}) => (
  <FormModal
    onSubmit={onSubmit}
    data={itemData}
    isOpen={isOpen}
    closeModal={closeModal}
    title={title}
    acceptButtonCaption={acceptButtonCaption}
    form={form}
  />
)

export default EditItemModal
