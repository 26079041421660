import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import Button from 'common/components/buttons/button'

type FileProps = {
  url: string
}

const DownloadQRCell: React.FC<FileProps> = ({ url }) => (
  <Button
    size="small"
    variant="outlined"
    onClick={(e) => {
      e.stopPropagation()
    }}
  >
    <a
      className="flex items-center gap-2"
      download
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <FontAwesomeIcon icon={faDownload} />
      <p>Download File</p>
    </a>
  </Button>
)

export default DownloadQRCell
