import { GetListTypes, Patient, Result } from 'types'
import * as api from './api'

export const getResults = async ({ page, size, order, sort }: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/result${isQueryParam ? query : ''}`)
}

// TODO undefined
export const getResult = async (id: string | undefined) =>
  api.get(`/result/${id}`)

// TODO undefined
export const updateResult = async (
  id: string | undefined,
  data: Result & Patient
) => api.put(`/result/${id}`, data)

export const createNewResult = async () => api.post('/result')

export const shareResult = async (id: string, data: { email: string }) =>
  api.post(`/result/${id}/share`, data)

export const sendResult = async (id: string) => api.post(`/result/${id}/send`)

export const removeResult = async (id: string) => api.del(`/result/${id}`)

export const getResultAnalyzes = async ({
  page,
  size,
  order,
  sort,
}: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/casa-analyze-result${isQueryParam ? query : ''}`)
}
