import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'

import QRCodePage from './qrCode.page'
import DoctorPage from './doctor.page'
import ProductPage from './product.page'
import ResultPage from './result.page'
import ResultPDFPage from './resultPdf.page'
import UsersPage from './users.page'
import NewAnalysis from './new-analysis.page'

const AppRoutes = () => {
  const [token] = useLocalStorage('token')

  if (token) {
    return (
      <Routes>
        <Route path="qrCode" element={<QRCodePage />} />
        <Route path="doctor" element={<DoctorPage />} />
        <Route path="product" element={<ProductPage />} />
        <Route path="result" element={<ResultPage />} />
        <Route path="result/:id" element={<ResultPDFPage />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="newAnalysis" element={<NewAnalysis />} />
        <Route path="*" element={<Navigate to="qrCode" />} />
      </Routes>
    )
  }

  return <Navigate to="/" />
}

export default AppRoutes
