import { useEffect } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'

import PAGINATION_CONSTANTS from 'constants/pagination_constants'

const useHandleTableChange = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setSearchParams(createSearchParams({ page: '1', size: '10' }))
  }, [])

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: { order: 'ascend' | 'descend'; field: string }
  ) => {
    const page = pagination.current.toString()
    const size = pagination.pageSize.toString()

    setSearchParams(
      createSearchParams({
        page,
        size,
        ...(sorter?.order && {
          sort: sorter.field,
          order: PAGINATION_CONSTANTS[sorter.order],
        }),
      })
    )
  }

  const page = searchParams.get('page')
  const size = searchParams.get('size')
  const sort = searchParams.get('sort')
  const order = searchParams.get('order')

  return { page, size, sort, order, handleTableChange }
}

export default useHandleTableChange
