import React from 'react'

import { Analyze } from 'types'
import PreviewVideoCell from './previewVideoCell'
import PreviewResultCell from './previewResultCell'
import ShareResultCell from './shareResultCell'
import SendResultCell from './sendResultCell'
import EnqueueResultCell from './enqueueResultCell'
import RemoveResultCell from './removeResult'

type Props = {
  analyze: Analyze
}

const ResultActionsCell: React.FC<Props> = ({ analyze }) => {
  console.log('prevent return statement error')
  return (
    <div className="flex items-center gap-4">
      <PreviewVideoCell url={analyze?.video?.url} />
      <EnqueueResultCell id={analyze?.id} />

      {analyze.result && (
        <>
          <PreviewResultCell id={analyze.result.id} />
          <ShareResultCell id={analyze.result.id} />
          <SendResultCell id={analyze.result.id} />
        </>
      )}
      <RemoveResultCell id={analyze?.id} />
    </div>
  )
}

export default ResultActionsCell
