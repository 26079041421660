import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import { login } from 'api/auth.api'
import { LoginFieldValues } from 'types'

type Returned = {
  token: string
  id: string
  role: string
}

export default () => {
  const navigate = useNavigate()

  return useMutation<Returned, Error, LoginFieldValues>((data) => login(data), {
    mutationKey: 'login',
    onSuccess: (data) => {
      window.localStorage.setItem('token', data.token)
      window.localStorage.setItem('userId', data.id)
      window.localStorage.setItem('role', data.role)
      navigate('/app')
    },
  })
}
