import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from 'common/components/inputs/input'
import Select from 'common/components/select'
import { USER_OPTIONS } from 'constants/select_constants'

type UserFormType = 'NEW_USER_FORM' | 'EDIT_USER_FORM'

type UserFormProps = {
  type: UserFormType
}

const UserForm: React.FC<UserFormProps> = ({ type }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="flex flex-col gap-5">
      <Input
        label="First Name"
        requiredField
        placeholder="First Name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.name?.message}
        {...register('name', {
          required: `Please enter user's first name`,
        })}
      />
      <Input
        label="Last Name"
        requiredField
        placeholder="Last Name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.surname?.message}
        {...register('surname', {
          required: `Please enter user's last name`,
        })}
      />
      <Input
        label="Email"
        placeholder="test@test.com"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors?.email?.message}
        requiredField
        {...register('email', {
          required: 'Please enter an email.',
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Please enter a valid email.',
          },
        })}
      />
      {type === 'NEW_USER_FORM' && (
        <Input
          label="Password"
          placeholder="********"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors?.password?.message}
          requiredField
          {...register('password', {
            required: `Please enter user's password`,
          })}
        />
      )}
      <Controller
        name="role"
        control={control}
        rules={{ required: 'Please select user role.' }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={USER_OPTIONS}
            id="role"
            label="Role"
            onChange={(val) => onChange(val)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={errors.role?.message}
            value={value || undefined}
            placeholder="-"
            requiredField
          />
        )}
      />
    </div>
  )
}

export default UserForm
