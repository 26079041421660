import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from 'common/components/buttons/button'

type Props = {
  id: string
}

const PreviewResultCell: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate()

  return (
    <Button
      size="small"
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation()
        navigate(`/app/result/${id}`)
      }}
    >
      <div className="flex items-center gap-1 text-xs">
        <p>Preview Result</p>
      </div>
    </Button>
  )
}

export default PreviewResultCell
