import React from 'react'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Spinner = () => (
  <div className="flex items-center justify-center text-primary-100">
    <FontAwesomeIcon icon={faSpinner} className={clsx('animate-spin')} />
  </div>
)

export default Spinner
