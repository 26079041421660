import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useLogin from 'hooks/auth/useLogin'

import PublicLayout from 'common/layouts/public.layout'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import ButtonModal from 'common/components/modals/buttonModal'
import Link from 'common/components/link'

import TERMS_OF_USE from 'constants/terms_of_use'
import PRIVACY_POLICY from 'constants/privacy_policy'
import { LoginFieldValues } from 'types'

const LoginPage = () => {
  const methods = useForm<LoginFieldValues>()
  const { mutate, isLoading, error } = useLogin()

  const onSubmit = (data: LoginFieldValues) => mutate(data)

  useEffect(() => {
    if (error instanceof Error) {
      toast(error.message, { type: 'error' })
    }
  }, [error])

  return (
    <PublicLayout>
      <div className="flex flex-col p-16 mx-auto my-12 space-y-6 tablet:rounded-3xl shadow-lg bg-slate-50 w-full tablet:w-[544px]">
        <h1 className="text-2xl text-black">SpermCell Login</h1>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <Input
              label="Email"
              placeholder="test@test.com"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={methods.formState.errors?.email?.message}
              {...methods.register('email', {
                required: 'Please enter an email.',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please enter a valid email.',
                },
              })}
            />
            <Input
              type="password"
              label={
                <div className="flex items-center justify-between">
                  <p>Password</p>
                  <Link href="/forgot-password">Forgot password?</Link>
                </div>
              }
              labelClassName="w-full"
              placeholder="Enter your password"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error={methods.formState.errors?.password?.message}
              {...methods.register('password', {
                required: 'Please enter a password.',
              })}
            />
            <SubmitButton
              variant="contained"
              status={isLoading ? 'disabled' : ''}
            >
              Login
            </SubmitButton>
          </form>
        </FormProvider>
        <div className="flex items-center justify-between">
          <ButtonModal
            title="Terms of Use"
            acceptButtonCaption="I Understand"
            content={TERMS_OF_USE}
            variant="default"
          >
            Terms of Use
          </ButtonModal>
          <ButtonModal
            title="Privacy Policy"
            acceptButtonCaption="I Understand"
            content={PRIVACY_POLICY}
            variant="default"
          >
            Privacy Policy
          </ButtonModal>
        </div>
      </div>
    </PublicLayout>
  )
}

export default LoginPage
