import { GetListTypes } from 'types'
import * as api from './api'

export const getQRCodes = async ({ page, size, sort, order }: GetListTypes) => {
  const query = `?page=${page}&size=${size}${sort ? `&sort=${sort}` : ''}`
  const isQueryParam = (page?.toString() && size?.toString()) || order

  return api.get(`/barcode${isQueryParam ? query : ''}`)
}
// Todo: any
export const createNewQRCode = async (data: any) => api.post('/barcode', data)

export const getFile = async (id: string) => api.get(`/file/${id}`)
