import React from 'react'
import clsx from 'clsx'

import { UserRoles } from 'types'

type RoleCellProps = {
  role: UserRoles
}

const ROLE_CELL_STYLES = {
  ADMIN: {
    label: 'Admin',
    color: 'text-white',
    backgroundColor: 'bg-primary-100',
  },
  USER: {
    label: 'Normal',
    color: 'text-black',
    backgroundColor: 'bg-gray-100',
  },
}

const RoleCell: React.FC<RoleCellProps> = ({ role }) => (
  <div
    className={clsx(
      'p-2 rounded-lg w-fit',
      ROLE_CELL_STYLES[role]?.backgroundColor
    )}
  >
    <p className={(clsx(''), ROLE_CELL_STYLES[role]?.color)}>
      {ROLE_CELL_STYLES[role]?.label}
    </p>
  </div>
)

export default RoleCell
