import React, { useMemo } from 'react'

import ResultActionsCell from 'common/views/results/resultActionsCell'
import { Analyze } from 'types'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const useResultColumns = () => {
  const columns = useMemo(
    () => [
      {
        title: 'Last Update Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        render: (analyze: Analyze) => (
          <p>{dayjs(analyze?.updatedDate).format('DD/MM/YYYY')}</p>
        ),
      },
      {
        title: 'Analyze Status',
        render: (analyze: Analyze) => <p>{analyze?.status}</p>,
      },
      {
        title: 'Result Date',
        render: (analyze: Analyze) => (
          <p>{dayjs(analyze?.createdDate).format('DD/MM/YYYY')}</p>
        ),
      },
      {
        title: 'Patient Name',
        render: (analyze: Analyze) => (
          <p>{`${analyze?.patient?.name ?? ''} ${
            analyze?.patient?.surname ?? ''
          }`}</p>
        ),
      },
      {
        title: 'Email',
        render: (analyze: Analyze) => <p>{analyze?.patient?.email}</p>,
      },
      {
        title: 'Phone Number',
        render: (analyze: Analyze) => <p>{analyze?.patient?.telephone}</p>,
      },
      {
        title: 'Total Sperm',
        render: (analyze: Analyze) => <p>{analyze?.result?.totalSperm}</p>,
      },
      {
        title: 'Volume',
        render: (analyze: Analyze) => <p>{analyze?.volume}</p>,
      },
      {
        title: 'Updated',
        render: (analyze: Analyze) =>
          analyze?.result?.updated ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="m-auto stroke-lime-600"
            />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          ),
      },
      {
        title: 'Mail Sent',
        render: (analyze: Analyze) =>
          analyze?.result?.mailSent ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="m-auto stroke-lime-600"
            />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          ),
      },
      {
        title: 'Actions',
        render: (analyze: Analyze) => <ResultActionsCell analyze={analyze} />,
      },
    ],
    []
  )

  return columns
}

export default useResultColumns
