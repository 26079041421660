import React, { useMemo } from 'react'
import { toast } from 'react-toastify'

import useDeleteDoctor from 'hooks/doctors/useDeleteDoctor'

import DeleteCell from 'common/components/Table/deleteCell'
import { Doctor } from 'types'
import NameCell from '../../common/views/doctors/nameCell'
import StatusCell from '../../common/views/statusCell'

type Props = {
  refetchDoctors: () => void
}

const useDoctorColumns = ({ refetchDoctors }: Props) => {
  const { mutateAsync, isSuccess } = useDeleteDoctor()

  const handleDelete = (id: string) => {
    mutateAsync(id).then(() => {
      toast('Doctor deleted successfully', { type: 'success' })
      refetchDoctors()
    })
  }

  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Full Name',
        dataIndex: '',
        key: '',
        render: (data: Doctor) => (
          <NameCell data={`${data.name} ${data.surname}`} />
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Analyze Count',
        dataIndex: 'analyzeCount',
        key: 'analyzeCount',
      },
      {
        title: 'Status',
        dataIndex: 'active',
        key: 'active',
        render: (data: boolean) => <StatusCell status={data} />,
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (data: string) => (
          <DeleteCell
            modalTitle="Delete Doctor"
            modalContent="Are you sure you want to delete doctor? This action cannot be undone."
            handleDelete={() => handleDelete(data)}
            isSuccess={isSuccess}
          />
        ),
      },
    ],
    []
  )

  return columns
}

export default useDoctorColumns
