import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import useNewQRCode from 'hooks/qrCode/useNewQRCode'

import QRCodeCreateModalForm from 'common/views/qrCode/qrCodeCreateModalForm'
import FormModal from 'common/components/modals/formModal'
import Button from 'common/components/buttons/button'
import { CreateQRCodeBody } from 'types'

type Props = {
  refetch: () => void
}

const QRCodeCreateModalButton: React.FC<Props> = ({ refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  const { mutateAsync, error } = useNewQRCode()

  const onSubmit = (data: CreateQRCodeBody) => {
    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutateAsync(data).then(() => {
      closeModal()
      toast('QR Code created successfully', { type: 'success' })
      refetch()
    })
  }

  useEffect(() => {
    if (error instanceof Error) {
      toast(error.message, { type: 'error' })
    }
  }, [error])

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faQrcode} />
          <p>Create</p>
        </div>
      </Button>
      <FormModal
        isOpen={isModalOpen}
        title="QR Code Creator"
        closeModal={closeModal}
        // TODO
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit}
        form={<QRCodeCreateModalForm />}
        acceptButtonCaption="Create"
        rejectButtonCaption="Cancel"
      />
    </>
  )
}

export default QRCodeCreateModalButton
