import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from 'common/components/inputs/input'
import Select from 'common/components/select'
import { STATUS_OPTIONS } from 'constants/select_constants'

const PRODUCT_OPTIONS = [
  { label: 'Bull', value: 'BULL' },
  { label: 'Human', value: 'HUMAN' },
]

const ProductForm = () => {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext()

  return (
    <div className="flex flex-col gap-5">
      <Input
        label="Name"
        placeholder="Product Name"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors.name?.message}
        requiredField
        {...register('name', {
          required: 'Please enter a product name.',
        })}
      />
      <Controller
        name="type"
        control={control}
        rules={{ required: 'Please select a type.' }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={PRODUCT_OPTIONS}
            id="product_options"
            label="Product Type"
            onChange={(val) => onChange(val)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={errors.type?.message}
            value={value || undefined}
            placeholder="-"
            requiredField
          />
        )}
      />
      <Controller
        name="active"
        control={control}
        rules={{ required: 'Please select product status.' }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={STATUS_OPTIONS}
            id="status"
            label="Status"
            onChange={(val) => onChange(val)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={errors.active?.message}
            value={value || undefined}
            placeholder="-"
            requiredField
          />
        )}
      />
    </div>
  )
}

export default ProductForm
