import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import useUsers from 'hooks/users/useUsers'
import useUserColumns from 'hooks/users/useUserColumns'
import useUser from 'hooks/users/useUser'
import useUpdateUser from 'hooks/users/useUpdateUser'
import useNewUser from 'hooks/users/useNewUser'
import useHandleTableChange from 'hooks/useHandleTableChange'

import AppLayout from 'common/layouts/app.layout'
import TableView from 'common/views/table.view'
import Button from 'common/components/buttons/button'
import FormModal from 'common/components/modals/formModal'
import UserForm from 'common/views/users/userForm'
import EditItemModal from 'common/views/editItemModal'
import { User } from 'types'

type NewUserTypes = {
  refetch: () => void
}

const NewUserButton: React.FC<NewUserTypes> = ({ refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)

  const { mutateAsync } = useNewUser()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = ({ id, ...rest }: User) => {
    mutateAsync(rest).then(() => {
      closeModal()
      toast('User created successfully.', { type: 'success' })
      refetch()
    })
  }

  return (
    <Button onClick={() => setIsModalOpen(true)} variant="outlined">
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faUser} />
        <p>Add User</p>
      </div>
      <FormModal
        form={<UserForm type="NEW_USER_FORM" />}
        isOpen={isModalOpen}
        closeModal={closeModal}
        // TODO
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit}
        acceptButtonCaption="Add"
        rejectButtonCaption="Cancel"
        title="Add New User"
      />
    </Button>
  )
}

const UsersPage = () => {
  const [role, setRole] = useState('')

  useEffect(() => {
    setRole(window.localStorage.getItem('role') as 'USER' | 'ADMIN')
  }, [])

  const { page, size, order, handleTableChange } = useHandleTableChange()

  const { data, isLoading, refetch } = useUsers({
    page: Number(page) - 1,
    size,
    sort: 'createdDate,desc',
    order,
  })
  const columns = useUserColumns({ refetch })

  // Edit Modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => setIsModalOpen(false)
  const [userId, setUserId] = useState('')
  const { data: itemData } = useUser(userId, { enabled: Boolean(userId) })

  const { mutateAsync } = useUpdateUser(userId)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = ({ id, ...rest }: User) => {
    mutateAsync(rest).then(() => {
      closeModal()
      toast('User information updated successfully.', { type: 'success' })
      refetch()
    })
  }

  return (
    <AppLayout>
      <TableView
        title="Users"
        columns={columns}
        data={data?.content}
        isLoading={isLoading}
        actionButton={role === 'ADMIN' && <NewUserButton refetch={refetch} />}
        onItemClick={(id) => {
          setIsModalOpen(true)
          setUserId(id)
        }}
        handleTableChange={handleTableChange}
        totalCount={data?.totalElements || 0}
      />
      {role === 'ADMIN' && (
        <EditItemModal
          isOpen={isModalOpen}
          itemData={itemData}
          closeModal={closeModal}
          title="Edit User"
          acceptButtonCaption="Update User Information"
          form={<UserForm type="EDIT_USER_FORM" />}
          onSubmit={onSubmit}
        />
      )}
    </AppLayout>
  )
}

export default UsersPage
