import React from 'react'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'

type SidebarProps = {
  className: string
  onItemClick: React.MouseEventHandler<HTMLButtonElement>
}

const menuItems = [
  { name: 'qrCode', label: 'QR Code', href: '/app/qrCode' },
  { name: 'doctor', label: 'Doctor', href: '/app/doctor' },
  { name: 'product', label: 'Product', href: '/app/product' },
  { name: 'result', label: 'Result', href: '/app/result' },
  { name: 'users', label: 'Users', href: '/app/users' },
  { name: 'newAnalysis', label: 'New Analysis', href: '/app/newAnalysis' },
]

const Sidebar: React.FC<SidebarProps> = ({ className, onItemClick }) => {
  const { '*': tab } = useParams()

  return (
    <aside
      className={clsx(
        'w-screen tablet:w-60 shrink-0 shadow-slate-300 shadow-lg',
        className
      )}
    >
      <ul className="flex flex-col gap-2 px-5 pt-8">
        {menuItems.map((item) => {
          const isActive = tab && tab.includes(item.name)

          return (
            <Link key={item.name} to={item.href}>
              <button
                type="button"
                onClick={onItemClick}
                className={clsx(
                  'flex items-center w-full gap-3 p-2 transition duration-500 shadow-sm ease-in-out rounded-lg cursor-pointer group hover:bg-primary-500 hover:text-white',
                  isActive && 'bg-primary-500 text-white'
                )}
              >
                {item.label}
              </button>
            </Link>
          )
        })}
      </ul>
    </aside>
  )
}

export default Sidebar
