import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    maxHeight: '500px',
    borderRadius: '8px',
    padding: 0,
  },
}

export const STATUSES = {
  primary: {
    icon: (
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className="text-primary-500"
      />
    ),
  },
  success: {
    icon: <FontAwesomeIcon icon={faCheckCircle} className="text-success-500" />,
  },
  warning: {
    icon: (
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className="text-warning-500"
      />
    ),
  },
  danger: {
    icon: (
      <FontAwesomeIcon icon={faExclamationCircle} className="text-danger-500" />
    ),
  },
}
