import React from 'react'
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline'

import useSignOut from 'hooks/useSignOut'

import Button from './buttons/button'

type HeaderProps = {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<HeaderProps> = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}) => {
  const signOut = useSignOut()

  return (
    <div className="flex justify-between items-center p-4 bg-primary-500 text-white">
      <div className="flex items-center gap-1">
        <Button
          onClick={() => setIsMobileMenuOpen((prev) => !prev)}
          className="flex tablet:hidden"
        >
          <div className="w-6 h-6">
            {isMobileMenuOpen ? <XIcon /> : <MenuAlt2Icon />}
          </div>
        </Button>
        <p className="font-bold text-xl">SpermCell</p>
      </div>
      <Button onClick={signOut} variant="default" color="transparent">
        Log out
      </Button>
    </div>
  )
}

export default Header
