import React from 'react'

type CaptionProps = {
  children: string
}

const Caption: React.FC<CaptionProps> = ({ children }) => (
  <p className="bg-primary-50 p-2 font-bold">{children}</p>
)

export default Caption
