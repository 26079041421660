import { deleteCasaResult, runCasaResult } from 'api/casaAnalyzeResult.api'
import { uploadFile } from 'api/file.api'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

export const useRunCasaAnalyze = (onsuccess: any) =>
  useMutation(
    (body: any) => {
      const data = {
        ...body,
        bovine: 'string',
        device: 'IOS',
        serial: 'string',
      }
      return uploadFile('sperm_file.mp4', data.file).then((response) => {
        const value = {
          ...data,
          file: '',
          fileId: response.id,
        }
        toast('File uploaded successfully', { type: 'success' })
        runCasaResult(value)
      })
    },
    { onSuccess: onsuccess }
  )

export const useDeleteCasaAnalyze = () =>
  useMutation(`remove-analyze-result`, (id: string) => {
    toast('Casa result Deleted', { type: 'error' })
    return deleteCasaResult(id)
  })
